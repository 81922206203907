<template>
  <p class="indicator">
    Processing please wait...
  </p>
</template>
<script>
export default {
  name: 'CommonProcessIndicator'
}
</script>
<style scoped>
.indicator {
  text-align: center;
}
</style>

import currentAdmin from '@/mixins/currentAdmin.js'
import logoutUserMixin from '@/mixins/logoutUser.js'
import ipRangeCheck from 'ip-range-check'

export default {
  mixins: [currentAdmin, logoutUserMixin],
  methods: {
    async updateUserData () {
      const response = await this.$axios.get('/admin/auth/user')
      this.$store.dispatch('user', response.data.data)

      const ca = this.currentAdmin
      const authorizedIP = (ip) => ca.ipWhitelist.length ? ca.ipWhitelist.some(entry => entry === ip || ipRangeCheck(ip, entry)) : true

      // Auto Logout Conditions
      const conditions = [
        !ca.active,
        ca.ipWhitelist.length && !authorizedIP(ca.ip)
      ]

      if (conditions.some(condition => condition)) this.logoutUser()
    }
  }
}

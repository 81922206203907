// import validator from 'validator'
export default {
  data: () => ({
    sort: {
      key: '',
      order: 'desc'
    }
  }),
  computed: {
    sortedItems () {
      const list = this.rowData.slice()
      if (this.sort.key) {
        list.sort((a, b) => {
          a = this.filterToUpper(a[this.sort.key])
          b = this.filterToUpper(b[this.sort.key])
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.order === 'asc' ? 1 : -1)
        })
      }
      return list
    }
  },
  methods: {
    filterToUpper (v) {
      if (typeof v === 'string') {
        return v.toUpperCase()
      }
      return v
    },
    sortBy (key, order) {
      this.sort.order = order
      this.sort.key = key
    }
  }
}

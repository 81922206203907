<template>
  <div class="faq-container">
    <div v-for="(value, index) in rowData" :key="index" class="faq-item">
      <h2>FAQ #{{ index + 1 }}</h2>
      <v-card class="main-card">
        <v-form ref="form" v-model="valid">
          <div>
            <label class="custom-label">Question</label>
            <v-text-field v-model="value.question" :value="value.question" :rules="questionRules" required
                          outlined
                          dense
            />
            <label class="custom-label">Answer</label>
            <v-text-field v-model="value.answer" :value="value.answer" :rules="answerRules" required
                          outlined dense
            />
            <label class="custom-label">Category</label>
            <v-select v-model="value.category" :items="categoryItems" item-value="id" item-text="name"
                      required outlined
                      dense class="dropdown"
            />
          </div>
          <div class="form-actions">
            <div v-if="errorMessage && value.id === selectedId" class="custom-error-container">
              {{ errorMessage }}
            </div>
            <v-btn class="custom-btn-error" :disabled="loading || !valid" :ripple="false" small
                   @click="deleteData(value.id)"
            >
              Delete
            </v-btn>
            <v-btn class="custom-btn-primary" :disabled="loading || !valid" :ripple="false" small
                   @click="updateData('up',value.id, value.answer, value.question, value.category, value.sort, index)"
            >
              Up
            </v-btn>
            <v-btn class="custom-btn-primary" :disabled="loading || !valid" :ripple="false" small
                   @click="updateData('down', value.id, value.answer, value.question, value.category, value.sort, index)"
            >
              Down
            </v-btn>
          </div>
        </v-form>
      </v-card>
    </div>
    <div class="add-button-container">
      <v-btn class="custom-btn-secondary" :disabled="loading" :ripple="false" small
             @click="addItem()"
      >
        <span>{{ addButtoText }}</span>
      </v-btn>
    </div>
    <v-card v-if="addNew" class="main-card">
      <v-form ref="addForm">
        <div>
          <label class="custom-label">Question</label>
          <v-text-field v-model="newQuestion" :rules="questionRules" required outlined
                        dense
          />
          <label class="custom-label">Answer</label>
          <v-text-field v-model="newAnswer" :rules="answerRules" required outlined
                        dense
          />
          <label class="custom-label">Category</label>
          <v-select v-model="newCategory" :items="categoryItems" item-value="id" item-text="name"
                    required outlined
                    dense class="dropdown"
          />
        </div>
        <div class="form-actions">
          <div v-if="errorMessage" class="custom-error-container">
            {{ errorMessage }}
          </div>
          <v-btn class="custom-btn-primary" :loading="loading" :disabled="loading" :ripple="false"
                 small
                 @click="saveNewItem"
          >
            Add
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </div>
</template>
<script>
import swalAlertMixin from '@/mixins/swalAlert.js'
import getErrorMessageMixin from '@/mixins/getErrorMessage.js'
import validator from 'validator'
export default {
  name: 'FAQs',
  mixins: [swalAlertMixin, getErrorMessageMixin],
  data: () => ({
    valid: true,
    rowData: [],
    sortItems: [],
    totalFAQs: 0,
    faqInputs: [],
    categoryItems: [],
    search: '',
    loading: false,
    editMode: false,
    selectedId: 0,
    selectedFAQIndex: 0,
    upSortNumber: 0,
    downSortNumber: 0,
    errorMessage: '',
    forDeleteIds: [],
    addNew: false,
    newQuestion: '',
    questionRules: [
      v => !validator.isEmpty(v) || 'Question is required'
    ],
    newAnswer: '',
    answerRules: [
      v => !validator.isEmpty(v) || 'Answer is required'
    ],
    newCategory: '',
    addButtoText: 'Add',
    nextTargetId: 0,
    nextTargetSort: 0
  }),
  created () {
    this.getData()
  },
  methods: {
    addItem () {
      if (!this.addNew) {
        this.addNew = true
        this.addButtoText = 'Cancel Add'
      } else {
        this.addNew = false
        this.addButtoText = 'Add'
      }

      this.selectedId = 0
      this.clearInputs()
    },
    async saveNewItem () {
      if (this.$refs.addForm.validate()) {
        this.loading = true
        const newSort = this.totalFAQs ? this.rowData[this.totalFAQs - 1].sort + 1 : 1
        try {
          await this.$axios.post('/admin/faqs', {
            category: this.newCategory,
            question: this.newQuestion,
            answer: this.newAnswer,
            sort: newSort
          })

          this.getData()
          this.selectedId = 0
          this.addNew = false
          this.addButtoText = 'Add'
          this.loading = false
        } catch (error) {
          this.errorMessage = this.getErrorMessage(error.response)
          this.loading = false
        }
      }
    },
    updateData (direction, id, answer, question, categoryId, sort, index) {
      if (index === 0 && direction === 'up') {
        this.sortSingleItem(id, answer, question, categoryId)
      } else if (index === this.rowData.length - 1 && direction === 'down') {
        this.sortSingleItem(id, answer, question, categoryId)
      } else {
        this.sortMultiItem(direction, id, sort, index)
      }
    },
    async sortSingleItem (id, answer, question, categoryId) {
      this.loading = true
      try {
        await this.$axios.put(`/admin/faqs/${id}`, {
          category: categoryId,
          question,
          answer
        })
        this.loading = false
      } catch (error) {
        this.errorMessage = this.getErrorMessage(error.response)
        this.loading = false
      }
    },
    async sortMultiItem (direction, id, sort, index) {
      this.loading = true
      if (direction === 'up') {
        sort--
        this.nextTargetId = this.rowData[index - 1].id
        this.nextTargetSort = sort + 1
      } else {
        sort++
        this.nextTargetId = this.rowData[index + 1].id
        this.nextTargetSort = sort - 1
      }
      try {
        // Need to update one after another
        await this.$axios.put(`/admin/faqs/${this.nextTargetId}`, {
          sort: 0
        })
        await this.$axios.put(`/admin/faqs/${id}`, {
          sort
        })
        await this.$axios.put(`/admin/faqs/${this.nextTargetId}`, {
          sort: this.nextTargetSort
        })
        this.getData()
        this.selectedId = 0
        this.loading = false
        this.errorMessage = ''
      } catch (error) {
        this.errorMessage = this.getErrorMessage(error.response)
        this.loading = false
      }
    },
    async getData () {
      const [rowData, categoryItems, totalFAQs] = await Promise.all([
        this.$axios.get('/public/faqs', {
          params: {
            sort: 'asc'
          }
        }),
        this.$axios.get('/public/faqs/categories'),
        this.$axios.get('/public/faqs/count')
      ])

      this.rowData = rowData.data.data

      for (let i = 0; i < this.rowData.length; i++) {
        this.sortItems.push({
          faqId: this.rowData[i].id,
          sortNumber: this.rowData[i].sort
        })
      }

      this.categoryItems = categoryItems.data.data
      this.newCategory = this.categoryItems[0].id
      this.totalFAQs = totalFAQs.data.data.count
    },
    async continueDeletion (id) {
      this.forDeleteIds.push(id)

      try {
        await this.$axios.delete('/admin/faqs', {
          data: {
            ids: this.forDeleteIds
          }
        })

        this.swalSuccessfullyDelete(this.forDeleteIds)
        this.getData()
        this.forDeleteIds = []
      } catch (error) {
        this.swalDeletionFailed(error)
      }
    },
    clearInputs () {
      this.newQuestion = ''
      this.newAnswer = ''
      this.newCategory = this.categoryItems[0].id
    }
  }
}
</script>
<style scoped>
.faq-container {
  margin-top: 30px;
}

.faq-item {
  margin-bottom: 30px;
}

.faq-item h2 {
  font-family: 'Mollen-SemiBold', sans-serif;
  font-size: 20px;
  color: var(--primary-color);
}

.v-form {
  margin: 0px;
}

.form-actions .v-btn {
  min-width: 90px !important;
}

.v-select {
  max-width: 405px !important;
}

.input-container {
  display: none;
}

.tags {
  color: var(--secondary-color);
}

.add-button-container {
  text-align: right;
  padding: 15px 0px;
}
</style>

<template>
  <div>
    <CommonPageTitle title="Payment Options" />
    <PaymentOptions />
  </div>
</template>
<script>

import CommonPageTitle from '@/components/common/CommonPageTitle.vue'
import PaymentOptions from '@/components/partials/paymentoptions/PaymentOptions.vue'

export default {
  name: 'MerchantsPaymentOptionsIndex',
  components: {
    CommonPageTitle,
    PaymentOptions
  }
}
</script>

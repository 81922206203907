<template>
  <div>
    <v-card class="main-card">
      <CommonDataFilter :selected-status="selectedStatus" :action="action" :custom-search="true" @applyFilter="applyFilter"
                        @applyAction="handleAction"
      />
      <div class="custom-table">
        <table>
          <thead slot="head">
            <th class="checkbox-col">
            <!-- checkbox -->
            </th>
            <th class="a1-col">
              Name  <CommonSortIcons v-model="sort.key" sort-key="name" @applySort="sortBy" />
            </th>
            <th class="a2-col">
              Email  <CommonSortIcons v-model="sort.key" sort-key="email" default-sort="asc" @applySort="sortBy" />
            </th>
            <th class="a2-col">
              IP Whitelist  <CommonSortIcons v-model="sort.key" sort-key="ipWhitelist" @applySort="sortBy" />
            </th>
            <th class="a3-col">
              Admin Level  <CommonSortIcons v-model="sort.key" sort-key="role" @applySort="sortBy" />
            </th>
            <th>
              Force Change PW  <CommonSortIcons v-model="sort.key" sort-key="pwForceChange" @applySort="sortBy" />
            </th>
            <th class="a3-col">
              Status  <CommonSortIcons v-model="sort.key" sort-key="active" @applySort="sortBy" />
            </th>
          </thead>
          <tbody>
            <tr v-for="row in sortedItems" :key="row.id">
              <td class="text-center">
                <input :id="row.id" v-model="checkedItems" :value="row.id" name="UserId"
                       type="checkbox"
                >
              </td>
              <td>{{ row.name }}</td>
              <td>{{ row.email }}</td>
              <td>{{ row.ipWhitelist.join() }}</td>
              <td>{{ row.role }}</td>
              <td>{{ row.pwForceChange }}</td>
              <td>{{ row.active ? 'Active' : 'InActive' }}</td>
            </tr>
            <tr v-if="!rowData.length">
              <td class="text-center" colspan="7">
                No data
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pagination-container">
        <CommonPaginationDetails :current-page="currentPage" :selected-entry="selectedEntry" :total-records="totalRecords" />
        <CommonPaginationLinks v-if="totalPages" v-model="currentPage" :page-count="totalPages" />
      </div>
    </v-card>
    <UsersForm ref="usersFormRef" v-model="showDialog" :selected-action="selectedAction" :user-ids="checkedItems"
               @doneAction="getTotalData"
    />
  </div>
</template>
<script>
import CommonDataFilter from '@/components/common/CommonDataFilter.vue'
import UsersForm from '@/components/partials/users/UsersForm.vue'
import CommonPaginationDetails from '@/components/common/CommonPaginationDetails.vue'
import CommonPaginationLinks from '@/components/common/CommonPaginationLinks.vue'
import isPaginationMixin from '@/mixins/isPagination.js'
import swalAlertMixin from '@/mixins/swalAlert.js'
import CommonSortIcons from '@/components/common/CommonSortIcons.vue'
import tableSorterMixin from '@/mixins/tableSorter.js'

export default {
  name: 'UsersDataTable',
  components: {
    CommonDataFilter,
    UsersForm,
    CommonPaginationDetails,
    CommonPaginationLinks,
    CommonSortIcons
  },
  mixins: [swalAlertMixin, isPaginationMixin, tableSorterMixin],
  data: () => ({
    rowData: [],
    selectedAction: 'view',
    selectedStatus: '',
    search: '',
    showDialog: false,
    checkedItems: [],
    id: '',
    isActive: true,
    action: [
      'view',
      'add',
      'edit',
      'delete'
    ]
  }),
  created () {
    this.applyFilter(this.selectedStatus, this.selectedEntry, this.search)
  },
  methods: {
    applyFilter (selectedStatus, selectedEntry, search) {
      this.search = search
      this.selectedStatus = selectedStatus
      this.selectedEntry = selectedEntry
      this.getTotalData()
    },
    async getTotalData () {
      this.checkedItems = []
      const adminCount = await this.$axios.get('/admin/admins/count', {
        params: {
          search: this.search
        }
      })

      this.totalRecords = adminCount.data.data.count
      this.currentPaginationDisplay()
      this.getPageData()
    },
    async getPageData () {
      const response = await this.$axios.get('/admin/admins', {
        params: {
          search: this.search,
          perPage: this.selectedEntry,
          currentPage: this.currentPage
        }
      })
      this.rowData = response.data.data
    },
    handleAction (action) {
      this.selectedAction = action
      if (action === 'add') {
        this.showDialog = true
      } else if (action === 'edit') {
        if (this.checkedItems.length) {
          this.$refs.usersFormRef.retrieveData()
          this.showDialog = true
        }
      } else if (action === 'delete') {
        if (this.checkedItems.length) {
          this.swalConfimationDelete()
        }
      }
    },
    async deleteData (myids) {
      try {
        await this.$axios.delete('/admin/admins', {
          data: {
            ids: myids
          }
        })
        this.swalSuccessfullyDelete(myids)
        this.getTotalData()
        this.checkedItems = []
      } catch (error) {
        this.swalDeletionFailed(error)
      }
    }

  }
}
</script>
<style scoped>

.a1-col {
  width: 250px ;
}

.a2-col {
  width: 300px ;
}

.a3-col {
  width: 160px ;
}
</style>

import { render, staticRenderFns } from "./SvgHamburgerMenu.vue?vue&type=template&id=658c89d8&scoped=true&"
var script = {}
import style0 from "./SvgHamburgerMenu.vue?vue&type=style&index=0&id=658c89d8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "658c89d8",
  null
  
)

export default component.exports
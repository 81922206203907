<template>
  <component :is="layout" />
</template>

<script>
import LayoutGeneral from '@/layouts/LayoutGeneral.vue'
import LayoutPanel from '@/layouts/LayoutPanel.vue'

export default {
  components: {
    LayoutGeneral,
    LayoutPanel
  },
  computed: {
    layout () {
      if (this.$route.meta.layout === 'panel') {
        return LayoutPanel
      }

      return LayoutGeneral
    }
  }
}
</script>

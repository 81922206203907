<template>
  <div>
    <CommonPageTitle title="Support > Tickets" />
    <TicketsDataTable />
  </div>
</template>
<script>

import CommonPageTitle from '@/components/common/CommonPageTitle.vue'
import TicketsDataTable from '@/components/partials/support/tickets/TicketsDataTable.vue'

export default {
  name: 'Tickets',
  components: {
    CommonPageTitle,
    TicketsDataTable
  }
}
</script>

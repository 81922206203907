import Swal from 'sweetalert2'
import getErrorMessageMixin from '@/mixins/getErrorMessage.js'

export default {
  mixins: [getErrorMessageMixin],
  data: () => ({
    swalOptsSuccess: {
      imageUrl: require('@/assets/images/Icons/SVG/awesome-smile-wink.svg'),
      confirmButtonText: 'Continue',
      customClass: {
        confirmButton: 'custom-btn-secondary'
      }
    },
    swalOptsDel: {
      title: 'Continue Deletion?',
      imageUrl: require('@/assets/images/Icons/SVG/awesome-exclamation-circle.svg'),
      showCancelButton: true,
      reverseButtons: true,
      focusConfirm: false,
      confirmButtonText: 'Delete',
      customClass: {
        confirmButton: 'custom-btn-error',
        cancelButton: 'custom-btn-primary'
      }
    }
  }),
  computed: {
    swalMsgDel () {
      return this.showDelCount
        ? `You will not be able to recover ${this.checkedItems.length} selected item(s).`
        : 'There is no forever in life, but in this case, deletion is forever.'
    }
  },
  methods: {
    async deleteData (id) {
      this.selectedId = 0
      this.addNew = false
      const result = await Swal.fire({ ...this.swalOptsDel, text: this.swalMsgDel })
      if (result.isConfirmed) this.continueDeletion(id)
    },
    async swalConfimationDelete () {
      const result = await Swal.fire({ ...this.swalOptsDel, text: this.swalMsgDel })
      if (result.isConfirmed) this.deleteData(this.checkedItems)
    },
    swalDeletionFailed (error) {
      Swal.fire({
        title: 'Deletion Failed!',
        text: this.getErrorMessage(error.response),
        imageUrl: require('@/assets/images/Icons/SVG/awesome-sad-tear.svg'),
        confirmButtonText: 'Okay...',
        customClass: {
          confirmButton: 'custom-btn-primary'
        }
      })
    },
    swalSuccessfullyDelete (myids) {
      this.selectedId = 0
      this.addNew = false
      Swal.fire({
        ...this.swalOptsSuccess,
        title: 'Deletion Success!',
        text: `You've successfully deleted ${myids.length} item(s).`
      })
    },
    swalSuccessfullyUpdate (msg) {
      Swal.fire({
        ...this.swalOptsSuccess,
        title: 'Update Success!',
        text: !msg ? 'You\'ve successfully updated your account.' : msg
      })
    },
    swalSuccessfullyAdded (msg) {
      Swal.fire({
        ...this.swalOptsSuccess,
        title: 'Addition Success!',
        text: !msg ? 'You\'ve successfully added an account.' : msg
      })
    }
  }
}

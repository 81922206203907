<template>
  <div>
    <CommonPageTitle title="Orders" />
    <OrdersDataTable />
  </div>
</template>
<script>

import CommonPageTitle from '@/components/common/CommonPageTitle.vue'
import OrdersDataTable from '@/components/partials/orders/OrdersDataTable.vue'

export default {
  name: 'Merchants',
  components: {
    CommonPageTitle,
    OrdersDataTable
  }
}
</script>

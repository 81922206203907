import validator from 'validator'
import validateOptionsMixin from '@/mixins/validateOptions.js'
import isNameMixin from '@/mixins/isName.js'

export default {
  mixins: [validateOptionsMixin, isNameMixin],
  data: () => ({
    nameRules: [
      v => !validator.isEmpty(v) || 'Name is required'
    ],
    emailRules: [
      v => !validator.isEmpty(v) || 'E-mail is required',
      v => validator.isEmail(v) || 'Email must be valid'
    ],
    passwordRules: [
      v => !validator.isEmpty(v) || 'Password is required'
    ],
    passwordConfirmRules: [
      v => !validator.isEmpty(v) || 'Confirm Password is required'
    ],
    forceChangePWRules: [
      v => !validator.isEmpty(v) || 'Force Change PW is required'
    ],
    statusRules: [
      v => !validator.isEmpty(v) || 'Status is required'
    ],
    ipWhitelistRules: []
  }),
  computed: {
    passwordConfirmationRule () {
      return () =>
        this.password === this.passwordConfirm || 'Confirm Password must match'
    },
    ips () {
      return this.ipWhitelist.length ? this.ipWhitelist.split('\n') : []
    }
  },
  created () {
    this.passwordRules.push(v => validator.isStrongPassword(v, this.strongPwOpts) || this.strongPwMsg)
    this.nameRules.push(v => this.isName(v) || 'Invalid name')
  },
  methods: {
    checkInvalidIPs (ips) {
      return !ips.filter(ip => ip)
        .every(ip => (validator.isIPRange(ip) || validator.isIP(ip)))
    },
    applyIPRules () {
      if (this.ipWhitelist) {
        this.ipWhitelistRules.push(v => !this.checkInvalidIPs(this.ips) || 'Invalid IP Address format') &&
          this.ipWhitelistRules.push(v => !/^(.*)(\r?\n\1)+$/gm.test(validator.trim(v)) || 'Duplicate IP\'s ')
      } else {
        this.ipWhitelistRules = []
      }
    }
  }
}

<template>
  <v-app-bar app>
    <SvgHamburgerMenu class="d-flex d-lg-none cursor" @click.native="$emit('applyToggle')" />
    <v-spacer />
    <div id="menu-container" v-click-outside="close">
      <button id="btn-user" type="button" :class="[active ? 'active' : 'inactive']" @click="active = !active">
        <span>{{ currentAdmin.name }}</span>
        <SvgChevron />
      </button>
      <nav v-show="active">
        <ul>
          <li>
            <router-link to="/account-settings">
              Account Settings
            </router-link>
          </li>
          <li>
            <a @click.prevent="logoutUser">
              Sign Out
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </v-app-bar>
</template>

<script>
import SvgHamburgerMenu from '@/components/svgs/SvgHamburgerMenu.vue'
import SvgChevron from '@/components/svgs/SvgChevron.vue'
import logoutUserMixin from '@/mixins/logoutUser.js'
import currentAdminMixin from '@/mixins/currentAdmin.js'

export default {
  name: 'TheTopNavigation',
  components: {
    SvgHamburgerMenu,
    SvgChevron
  },
  mixins: [logoutUserMixin, currentAdminMixin],
  data: () => ({
    active: false
  }),
  watch: {
    $route () {
      this.close()
    }
  },
  methods: {
    close () { // vuetify `v-click-outside` does not accept direct assignments
      this.active = false
    }
  }
}
</script>

<style scoped>
#menu-container {
  position: relative;
  min-width: 280px;
}

#btn-user {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 18px;
  padding: 30px 54px;
  color: var(--primary-color);
  background: transparent;
  transition: 0.2s ease-in-out;
  font-size: 22px !important; /* override global !important */
  border-radius: 12px !important; /* override global !important */
  font-family: 'Bolt-Regular', sans-serif !important; /* override global !important */
  text-transform: initial !important; /* override global !important */
}

#btn-user.active {
  color: #fff;
  background: var(--secondary-color);
}

#btn-user.active > svg {
  stroke: #fff;
  transform: rotate(180deg);
}

#btn-user.inactive:hover {
  color: var(--secondary-color);
}

#btn-user.inactive:hover > svg {
  stroke: var(--secondary-color);
}

nav {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 54px;
  background: #fff;
  border-radius: 0 0 12px 12px;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
  z-index: -1;
}

nav ul {
  list-style: none;
  padding: 30px 0 24px;
}

nav ul li:not(:first-child) {
  padding-top: 18px;
}

nav a {
  color: var(--primary-color);
  font-size: 16px;
  text-decoration: none;
}

nav a:hover {
  color: var(--secondary-color);
}

.v-app-bar {
  background: #fff !important;
  padding: 20px 35px 0 0;
  height: 100px !important;
}

@media screen and (max-width: 600px) {

  .v-app-bar {
    padding-right: 0;
  }
}
</style>

<template>
  <div>
    <h1 class="auth-title">
      Password Reset
    </h1>
    <v-form ref="form" class="auth-custom-form" @submit.prevent="">
      <v-text-field v-model="email" :rules="emailRules" class="custom-text-field" type="email"
                    label="Email" required single-line
      />
      <v-text-field v-model="token" :rules="tokenRules" class="custom-text-field" type="text"
                    label="Reset Token" required single-line
      />
    </v-form>

    <div class="auth-action-footer">
      <v-btn type="submit" class="custom-btn-secondary" block :ripple="false"
             @click="handleRequestNext()"
      >
        Next
      </v-btn>
    </div>
    <div class="auth-action-footer">
      <v-btn type="submit" class="custom-btn-primary" block :ripple="false"
             @click="$emit('request','authForgotPassword')"
      >
        Back
      </v-btn>
    </div>
    <div class="panel-action" @click="$emit('request','authForgotPassword')">
      I don't have a request token!
    </div>
    <div class="form-carousel-nav-container">
      <ul class="form-carousel-nav">
        <li class="active" />
        <li @click="handleRequestNext()" />
      </ul>
    </div>
  </div>
</template>
<script>
import validator from 'validator'
export default {
  name: 'AuthPasswordResetOne',
  data () {
    return {
      email: '',
      token: '',
      emailRules: [
        v => !validator.isEmpty(v) || 'E-mail is required',
        v => validator.isEmail(v) || 'E-mail must be valid'
      ],
      tokenRules: [
        v => !validator.isEmpty(v) || 'Token is required'
      ]
    }
  },
  methods: {
    handleRequestNext () {
      this.$emit('request', 'authPasswordResetTwo')
      this.$emit('passEmail', this.email)
      this.$emit('passToken', this.token)
    }
  }
}
</script>

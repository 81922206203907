<template>
  <div>
    <CommonPageTitle title="Shipping" />
    <ShippingDataColumns />
  </div>
</template>
<script>

import CommonPageTitle from '@/components/common/CommonPageTitle.vue'
import ShippingDataColumns from '@/components/partials/shipping/ShippingDataColumns.vue'

export default {
  name: 'Merchants',
  components: {
    CommonPageTitle,
    ShippingDataColumns
  }
}
</script>

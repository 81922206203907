<template>
  <div class="auth">
    <div class="wrapper">
      <img class="logo" src="@/assets/images/logo_full.png" alt="ANEC Admin" link>
      <v-card class="container">
        <div class="form-content">
          <AuthLogin v-show="activeLogin" @request="activeComponent" />
          <AuthForgotPassword v-show="activeForgot" @request="activeComponent" />
          <AuthPasswordResetOne v-show="activeResetOne" @request="activeComponent" @passEmail="getEmail" @passToken="getToken" />
          <AuthPasswordResetTwo v-show="activeResetTwo" :get-email="email" :get-token="token" @request="activeComponent" />
          <AuthSuccess v-if="activeSuccess" @request="activeComponent" />
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import AuthLogin from '@/components/partials/auth/AuthLogin.vue'
import AuthForgotPassword from '@/components/partials/auth/AuthForgotPassword.vue'
import AuthPasswordResetOne from '@/components/partials/auth/AuthPasswordResetOne.vue'
import AuthPasswordResetTwo from '@/components/partials/auth/AuthPasswordResetTwo.vue'
import AuthSuccess from '@/components/partials/auth/AuthSuccess.vue'
export default {
  name: 'AuthIndex',

  components: {
    AuthLogin,
    AuthForgotPassword,
    AuthPasswordResetOne,
    AuthPasswordResetTwo,
    AuthSuccess
  },
  data () {
    return {

      isVisible: true,
      isHide: false,
      object: 'authLogin',
      email: '',
      token: ''
    }
  },
  computed: {
    activeLogin () {
      return this.object === 'authLogin' ? this.isVisible : this.isHide
    },
    activeForgot () {
      return this.object === 'authForgotPassword' ? this.isVisible : this.isHide
    },
    activeResetOne () {
      return this.object === 'authPasswordResetOne' ? this.isVisible : this.isHide
    },
    activeResetTwo () {
      return this.object === 'authPasswordResetTwo' ? this.isVisible : this.isHide
    },
    activeSuccess () {
      return this.object === 'authSuccess' ? this.isVisible : this.isHide
    }
  },
  methods: {
    activeComponent (get) {
      this.object = get
    },
    getEmail (email) {
      this.email = email
    },
    getToken (token) {
      this.token = token
    }

  }
}
</script>

<style scoped>
.auth {
  margin-top: 60px;
}

.wrapper {
  margin: auto;
  text-align: center;
}

.logo {
  width: 180px;
  height: 180px;
  margin-bottom: 10px;
}

.v-card {
  max-width: 774px;
  color: #fff !important;
}

.v-card h2 {
  padding-top: 45px;
  font-family: 'Mollen-SemiBold', sans-serif;
  font-size: 28px;
}

.form-content {
  margin: 50px 0px;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0px 9px 12px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(7px);
}
@media only screen and (max-width: 600px) {

  .custom-text-field .v-label {
    font-family: 'Mollen-Regular', sans-serif;
    font-size: 11px;
    color: #fff;
  }

  .logo {
    width: 90px;
    height: 90px;
    margin-bottom: 18px;
  }

  .v-card {
    max-width: 290px;
    color: #fff !important;
  }

  .v-card h2 {
    padding-top: 15px;
    font-family: 'Mollen-SemiBold', sans-serif;
    font-size: 18px;
  }

  .form-content {
    margin: 10px;
  }
}
</style>

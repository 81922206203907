<template>
  <div>
    <h1 class="auth-title">
      Password Reset
    </h1>
    <v-form ref="form" class="auth-custom-form" @submit.prevent="validate">
      <v-text-field v-model="newPassword" class="custom-text-field" type="password" label="New Password"
                    :rules="passwordRules" required single-line
      />
      <v-text-field v-model="newPasswordConfirm" class="custom-text-field" type="password" label="Comfirm Password"
                    :rules="passwordConfirmRules.concat(passwordConfirmationRule)" required single-line
      />
      <div v-if="errorMessage" v-show="alertError" class="custom-error-container">
        * {{ errorMessage }}
      </div>
      <div class="auth-action-footer">
        <v-btn type="submit" class="custom-btn-secondary" block :ripple="false"
               :loading="loading" :disabled="loading"
        >
          Next
        </v-btn>
      </div>
    </v-form>
    <div class="auth-action-footer">
      <v-btn type="submit" class="custom-btn-primary" block :ripple="false"
             @click="$emit('request','authPasswordResetOne')"
      >
        Back
      </v-btn>
    </div>

    <div class="form-carousel-nav-container p-top">
      <ul class="form-carousel-nav">
        <li @click="$emit('request','authPasswordResetOne')" />
        <li class="active" />
      </ul>
    </div>
  </div>
</template>
<script>
import getErrorMessageMixin from '@/mixins/getErrorMessage.js'
import validateOptionsMixin from '@/mixins/validateOptions.js'
import validator from 'validator'
export default {
  name: 'AuthPasswordResetTwo',
  mixins: [getErrorMessageMixin, validateOptionsMixin],
  props: {
    getEmail: {
      type: String,
      default: ''
    },
    getToken: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      errorMessage: '',
      accountType: 'admin',
      newPassword: '',
      newPasswordConfirm: '',
      passwordRules: [
        v => !validator.isEmpty(v) || 'Password is required',
        v => validator.isStrongPassword(v, this.strongPwOpts) || this.strongPwMsg
      ],
      passwordConfirmRules: [v => !validator.isEmpty(v) || 'Confirm Password is required'],
      alertError: false,
      loading: false
    }
  },
  computed: {
    passwordConfirmationRule () {
      return () =>
        this.newPassword === this.newPasswordConfirm || 'Password must match'
    }
  },
  methods: {
    validate () {
      if (this.$refs.form.validate()) {
        this.handleSubmit()
      }
    },
    async handleSubmit () {
      this.loading = true
      try {
        await this.$axios.put('/admin/account/pwreset', {
          email: this.getEmail,
          accountType: this.accountType,
          newPassword: this.newPassword,
          newPasswordConfirm: this.newPasswordConfirm,
          token: this.getToken
        })
        this.alertError = false
        this.$emit('request', 'authSuccess')
        this.loading = false

        // Reset Fields
        this.accountType = ''
        this.newPassword = ''
        this.newPasswordConfirm = ''
      } catch (error) {
        this.errorMessage = this.getErrorMessage(error.response)
        this.alertError = true
        this.loading = false
      }
    }
  }
}
</script>

<template>
  <div class="icons">
    <SvgArrow :class="{ active : order === 'asc' && sortKey === value || (defaultSort === 'asc' && !value) }" @click="sortApply('asc')" />
    <SvgArrow class="down" :class="{ active : order === 'desc' && sortKey === value || (defaultSort === 'desc' && !value) }" @click="sortApply('desc')" />
  </div>
</template>
<script>
import SvgArrow from '@/components/svgs/SvgArrow.vue'
export default {
  name: 'CommonSortIcons',
  components: {
    SvgArrow
  },
  props: {
    defaultSort: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    sortKey: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    order: 'asc'
  }),
  methods: {
    sortApply (order) {
      this.order = order
      this.$emit('applySort', this.sortKey, order)
    }
  }
}
</script>
<style scoped>
.down {
  transform: rotate(180deg);
  margin-left: 4px;
}

.icons {
  float: right;
}
</style>

<template>
  <div class="options-container">
    <div v-for="(value, index) in rowData" :key="index" class="options-item">
      <h2>Option #{{ index + 1 }}</h2>
      <v-card class="main-card">
        <v-form ref="form" v-model="valid">
          <div>
            <label class="custom-label">Name</label>
            <v-text-field v-model="value.name" :value="value.name" :rules="nameRules" required
                          outlined
                          dense
            />
            <label class="custom-label">Description</label>
            <v-text-field v-model="value.description" :value="value.description" :rules="descriptionRules" required
                          outlined
                          dense
            />
            <v-row class="custom-row p-top">
              <v-col cols="12" sm="8">
                <label class="custom-radio-label">
                  <input v-model="value.active" type="radio" class="custom-radio" name="active"
                         :value="true"
                  >Active
                </label>
                <label class="custom-radio-label">
                  <input v-model="value.active" type="radio" class="custom-radio" name="active"
                         :value="false"
                  >Inactive
                </label>
              </v-col>
            </v-row>
          </div>
          <div class="form-actions">
            <div v-if="errorMessage && value.id === selectedId" class="custom-error-container">
              {{ errorMessage }}
            </div>
            <v-btn class="custom-btn-error" :disabled="loading || !valid" :ripple="false" small
                   @click="deleteData(value.id)"
            >
              Delete
            </v-btn>
            <v-btn class="custom-btn-primary" :disabled="loading || !valid" :ripple="false" small
                   @click="updateData(value.id, value.name, value.description, value.active)"
            >
              Update
            </v-btn>
          </div>
        </v-form>
      </v-card>
    </div>
    <div class="add-button-container">
      <v-btn class="custom-btn-secondary" :disabled="loading" :ripple="false" small
             @click="addItem()"
      >
        <span>{{ addButtonText }}</span>
      </v-btn>
    </div>
    <v-card v-if="addNew" class="main-card">
      <v-form ref="addForm">
        <div>
          <label class="custom-label">Name</label>
          <v-text-field v-model="newName" :rules="nameRules" required outlined
                        dense
          />
          <label class="custom-label">Description</label>
          <v-text-field v-model="newDescription" :rules="descriptionRules" required outlined
                        dense
          />
          <v-row class="custom-row p-top">
            <v-col cols="12" sm="8">
              <label class="custom-radio-label">
                <input v-model="newActive" type="radio" class="custom-radio"
                       name="newActive"
                       :value="true"
                >Active
              </label>
              <label class="custom-radio-label">
                <input v-model="newActive" type="radio" class="custom-radio" name="newActive"
                       :value="false"
                >Inactive
              </label>
            </v-col>
          </v-row>
        </div>
        <div class="form-actions">
          <div v-if="errorMessage" class="custom-error-container">
            {{ errorMessage }}
          </div>
          <v-btn class="custom-btn-primary" :loading="loading" :disabled="loading" :ripple="false"
                 small
                 @click="saveNewItem"
          >
            Add
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </div>
</template>
<script>
import swalAlertMixin from '@/mixins/swalAlert.js'
import getErrorMessageMixin from '@/mixins/getErrorMessage.js'
import validateOptionsMixin from '@/mixins/validateOptions.js'
import validator from 'validator'
export default {
  name: 'FAQs',
  mixins: [swalAlertMixin, getErrorMessageMixin, validateOptionsMixin],
  data: () => ({
    valid: true,
    rowData: [],
    search: '',
    loading: false,
    editMode: false,
    selectedId: 0,
    errorMessage: '',
    forDeleteIds: [],
    nameRules: [],
    descriptionRules: [],
    addNew: false,
    newName: '',
    newDescription: '',
    newActive: true,
    addButtonText: 'Add'
  }),
  created () {
    this.nameRules.push(v => !validator.isEmpty(v, this.isEmptyOpts) || 'Payment option name is required')
    this.nameRules.push(v => validator.isAlpha(v, this.isLocale.alphaNum, this.isAlphaNumOpts) || 'Invalid format name')
    this.descriptionRules.push(v => !validator.isEmpty(v, this.isEmptyOpts) || 'Description is required')
    this.getData()
  },
  methods: {
    addItem () {
      if (!this.addNew) {
        this.addNew = true
        this.addButtonText = 'Cancel Add'
      } else {
        this.addNew = false
        this.addButtonText = 'Add'
      }

      this.selectedId = 0
      this.clearInputs()
    },
    async saveNewItem () {
      if (this.$refs.addForm.validate()) {
        this.loading = true
        try {
          await this.$axios.post('/admin/merchant-payment-options', {
            name: this.newName,
            description: this.newDescription,
            active: this.newActive
          })

          this.getData()
          this.selectedId = 0
          this.addNew = false
          this.addButtonText = 'Add'
          this.loading = false
        } catch (error) {
          this.errorMessage = this.getErrorMessage(error.response)
          this.loading = false
        }
      }
    },
    async updateData (id, name, description, active) {
      this.loading = true
      this.selectedId = id
      try {
        await this.$axios.put(`/admin/merchant-payment-options/${id}`, {
          name,
          description,
          active
        })
        this.swalSuccessfullyUpdate('Payment option updated')
        this.errorMessage = ''
        this.loading = false
      } catch (error) {
        this.errorMessage = this.getErrorMessage(error.response)
        this.loading = false
      }
    },
    async getData () {
      const rowData = await this.$axios.get('/admin/merchant-payment-options')
      this.rowData = rowData.data.data
    },
    async continueDeletion (id) {
      this.forDeleteIds.push(id)

      try {
        await this.$axios.delete('/admin/merchant-payment-options', {
          data: {
            ids: this.forDeleteIds
          }
        })

        this.swalSuccessfullyDelete(this.forDeleteIds)
        this.getData()
        this.forDeleteIds = []
      } catch (error) {
        this.swalDeletionFailed(error)
      }
    },
    clearInputs () {
      this.newName = ''
      this.newDescription = ''
      this.newActive = true
    }
  }
}
</script>
<style scoped>
.options-container {
  margin-top: 30px;
}

.options-item {
  margin-bottom: 30px;
}

.options-item h2 {
  font-family: 'Mollen-SemiBold', sans-serif;
  font-size: 20px;
  color: var(--primary-color);
}

.v-form {
  margin: 0px;
}

.form-actions .v-btn {
  min-width: 90px !important;
}

.v-select {
  max-width: 405px !important;
}

.input-container {
  display: none;
}

.tags {
  color: var(--secondary-color);
}

.add-button-container {
  text-align: right;
  padding: 15px 0px;
}
</style>

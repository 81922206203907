<template>
  <div>
    <CommonPageTitle title="Payables" />
    <PayablesDataTable />
  </div>
</template>
<script>

import CommonPageTitle from '@/components/common/CommonPageTitle.vue'
import PayablesDataTable from '@/components/partials/payables/PayablesDataTable.vue'

export default {
  name: 'Payables',
  components: {
    CommonPageTitle,
    PayablesDataTable
  }
}
</script>

export default {
  methods: {
    getErrorMessage (error) {
      if (error) {
        return error.data.errors[0].detail
      } else {
        return 'Can\'t connect to the server.'
      }
    }
  }
}

<template>
  <div id="detail-pipe">
    <div v-for="(value, index) in items" :key="index" class="item"
         :class="[size, { switcher : withSwitchView }, { highlight : selectedItem === index }]"
         @click="withSwitchView ? switchView(value, index) : null"
    >
      {{ value.label || value }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'CommonDetailPipe',
  props: {
    items: {
      type: Array,
      default: () => ([])
    },
    size: {
      type: String,
      default: ''
    },
    withSwitchView: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    selectedItem: 0
  }),
  methods: {
    switchView (val, index) {
      this.$emit('switchView', val)
      this.selectedItem = index
    }
  }
}
</script>
<style scoped>
#detail-pipe {
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
  font-family: 'Mollen-Semibold', sans-serif;
  font-size: 24px;
  color: var(--primary-color);
}

.small {
  font-family: 'Mollen-Medium', sans-serif;
  font-size: 20px;
}

.switcher {
  cursor: pointer;
}

.item:not(:first-child):before {
  content: '|';
  color: var(--primary-color);
}

.highlight,
.switcher:hover {
  color: var(--secondary-color);
}
</style>

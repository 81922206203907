import { render, staticRenderFns } from "./TheSideNavigation.vue?vue&type=template&id=3d880e5d&scoped=true&"
import script from "./TheSideNavigation.vue?vue&type=script&lang=js&"
export * from "./TheSideNavigation.vue?vue&type=script&lang=js&"
import style0 from "./TheSideNavigation.vue?vue&type=style&index=0&id=3d880e5d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d880e5d",
  null
  
)

export default component.exports
<template>
  <div class="filter-container">
    <v-form @submit.prevent>
      <v-row>
        <v-col :sm="2">
          Show <select v-model="selectedEntry" name="entries" class="custom-input"
                       @change="applyFilter(selectedStatus)"
          >
            <option v-for="item in entries" :key="item" :value="item" :selected="selectedEntry === item">
              {{ item }}
            </option>
          </select> entries
        </v-col>
        <v-col v-if="status" cols="12" sm="5">
          <ul v-if="status" class="status-filter">
            <li v-for="item in status" :key="item.type" :class="{ active : selectedStatus === item.type }"
                @click="applyFilter(item.type)"
            >
              {{ item.type }} ({{ item.count }})
            </li>
          </ul>
        </v-col>
        <v-col :sm="action && status ? 3 : customSearch ? 8 : customDetail ? 11 : 0" class="text-right">
          <v-row v-if="autoRefresh">
            <v-col :sm="status ? 4 : 8">
              <div class="autorefresh">
                <label><input v-model="chkAutoRefresh" type="checkbox" @change="startAutoRefresh"> Auto refresh</label>
              </div>
            </v-col>
            <v-col :sm="status ? 8 : 4">
              {{ customLabel }} <input v-model="search" type="text" name="search" class="custom-input"
                                       @keyup.enter="applyFilter(selectedStatus)" @input="applyFilter(selectedStatus)"
              >
            </v-col>
          </v-row>
          <div v-else>
            {{ customLabel }} <input v-model="search" type="text" name="search" class="custom-input"
                                     @keyup.enter="applyFilter(selectedStatus)" @input="applyFilter(selectedStatus)"
            >
          </div>
        </v-col>

        <v-col v-if="actionHide" :sm="2" class="text-right">
          Status <select v-model="selectedPaymentStatus" name="paymentStatus" class="custom-input"
                         @change="applyFilter(selectedStatus)"
          >
            <option v-for="item in paymentStatus" :key="item" :value="item" :selected="selectedPaymentStatus === item">
              {{ item }}
            </option>
          </select>
        </v-col>
        <v-col v-if="actionHide" :sm="3" class="text-right">
          TimeFrame  <select v-model="selectedTimeFrame" name="timeFrame" class="custom-input"
                             @change="applyFilter(selectedStatus)"
          >
            <option v-for="item in timeFrame" :key="item" :value="item" :selected="selectedTimeFrame === item">
              {{ item }}
            </option>
          </select>
        </v-col>
        <v-col v-if="action" class="text-right">
          Action <select v-model="selectedAction" name="action" class="custom-input"
                         @change="applyAction(selectedAction)"
          >
            <option v-for="item in action" :key="item" :value="item" :selected="selectedAction === item">
              {{ item }}
            </option>
          </select>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
export default {
  name: 'CommonDataFilter',
  props: {
    status: {
      type: Array,
      default: null
    },
    selectedStatus: {
      type: String,
      default: ''
    },
    action: {
      type: Array,
      default: null
    },
    actionHide: {
      type: Boolean,
      default: false
    },
    customSearch: {
      type: Boolean,
      default: false
    },
    customDetail: {
      type: Boolean,
      default: false
    },
    autoRefresh: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    entries: [5, 10, 15, 25, 50],
    timeFrame: ['Today', 'This past week', 'This past month', 'This past year', 'All time'],
    paymentStatus: ['All', 'Paid', 'Unpaid'],
    selectedTimeFrame: 'Today',
    selectedPaymentStatus: 'All',
    selectedEntry: 5,
    selectedAction: 'view',
    search: '',
    chkAutoRefresh: false,
    timer: null
  }),
  computed: {
    customLabel () {
      return this.actionHide ? 'Merchant' : 'Search'
    }
  },
  destroyed () {
    clearInterval(this.timer)
  },
  methods: {
    applyFilter (status) {
      this.$emit('applyFilter', status, this.selectedEntry, this.search, this.selectedTimeFrame, this.selectedPaymentStatus)
    },
    applyAction (action) {
      this.selectedAction = action
      this.$emit('applyAction', action)
      this.selectedAction = 'view'
    },
    startAutoRefresh () {
      // Refresh data every minute
      this.chkAutoRefresh ? this.timer = setInterval(() => this.$emit('applyAutoRefresh'), 60000) : clearInterval(this.timer)
    }
  }
}
</script>

<style scoped>
.filter-container {
  font-family: 'Mollen-Regular', sans-serif;
  font-size: 14px;
  color: var(--primary-color);
  margin-bottom: 30px;
}

.custom-input {
  font-family: 'Mollen-Regular', sans-serif;
  border: 1px solid #d0d2d9;
  border-radius: 3px;
  padding-left: 1.6px;
  font-size: 14px;
}

.filter-container select {
  padding-left: 3px;
  height: 25px !important;
  appearance: menulist !important;
}

.filter-container input {
  height: 25px !important;
}

.status-filter {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.status-filter > li {
  float: left;
  display: block;
  padding-top: 3px;
  padding-right: 40px;
  text-decoration: none;
  color: var(--primary-color);
  cursor: pointer;
  text-transform: capitalize;
}

.status-filter > li:hover {
  color: var(--secondary-color) !important;
}

.active {
  color: var(--secondary-color) !important;
}

.autorefresh {
  float: right;
}

.autorefresh label {
  display: block;
  padding-left: 15px;
  text-indent: -15px;
  margin-top: -2px;
}

.autorefresh input {
  width: 13px;
  height: 13px;
  padding: 0;
  margin:0;
  vertical-align: bottom;
  position: relative;
  top: -0.5px;
  *overflow: hidden;
}

</style>

<template>
  <v-row>
    <v-col v-for="data in rowData" :key="data.id" cols="12" md="3">
      <v-card :class="{
        'mini-card': selectedId === data.id,
        'mini-card-inactive': data.active === false,
        'mini-card mini-card-fixed-height': selectedId !== data.id
      }"
      >
        <div class="card-title">
          <span v-show="selectedId !== data.id">{{ data.name }}
            <span v-show="data.active === false">(Inactive)</span>
          </span>
        </div>
        <v-text-field v-show="selectedId === data.id" v-model="name" :autofocus="true" class="centered-input"
                      :placeholder="`${titleDescription} Name`" dense @keyup.enter="saveChanges(data.id)"
        />
        <v-text-field v-if="customElement" v-show="selectedId === data.id" v-model="url" class="centered-input"
                      placeholder="Site Url" dense @keyup.enter="saveChanges(data.id)"
        />
        <div v-if="customElement" v-show="selectedId === data.id" class="radio-input">
          <label class="custom-radio-label">
            <input v-model="active" type="radio" class="custom-radio" :name="data.id"
                   :value="true"
            >Active
          </label>
          <label class="custom-radio-label p-left">
            <input v-model="active" type="radio" class="custom-radio" :name="data.id"
                   :value="false"
            >Inactive
          </label>
        </div>
        <div class="card-action">
          <v-btn class="custom-btn-primary" small :disabled="disabled" :ripple="false"
                 @click="editItem(data.id, data.name, data.url, data.active)"
          >
            <span v-if="selectedId !== data.id">Edit</span>
            <span v-else>Cancel</span>
          </v-btn>
          <v-btn v-show="selectedId !== data.id" class="custom-btn-error" :disabled="disabled" :ripple="false"
                 small
                 @click="deleteData(data.id)"
          >
            Delete
          </v-btn>
          <v-btn v-show="selectedId === data.id" class="custom-btn-secondary" :disabled="disabled" :ripple="false"
                 small
                 @click="saveChanges(data.id)"
          >
            Save
          </v-btn>
        </div>
      </v-card>
      <div v-if="errorMessage && selectedId === data.id" class="mini-card-custom-error-container">
        {{ errorMessage }}
      </div>
    </v-col>
    <v-col cols="12" md="3">
      <v-card v-show="addNew === false" class="mini-card-add mini-card-inactive" :ripple="false" @click="addItem">
        <img class="icon-svg" :src="require('@/assets/images/Icons/SVG/awesome-plus-circle.svg')">
        <div class="card-title">
          Add {{ titleDescription }}
        </div>
      </v-card>
      <v-card v-show="addNew === true" class="mini-card">
        <div class="card-title">
          <p>Add {{ titleDescription }}</p>
        </div>
        <v-text-field v-model="name" class="centered-input" :autofocus="true" :placeholder="`${titleDescription} Name`"
                      dense @keyup.enter="saveNewItem"
        />
        <v-text-field v-if="customElement" v-model="url" class="centered-input" placeholder="Site Url"
                      dense
                      @keyup.enter="saveNewItem"
        />
        <div v-if="customElement" class="radio-input">
          <label class="custom-radio-label">
            <input v-model="active" type="radio" class="custom-radio" name="active"
                   :value="true"
            >Active
          </label>
          <label class="custom-radio-label p-left">
            <input v-model="active" type="radio" class="custom-radio" name="active"
                   :value="false"
            >Inactive
          </label>
        </div>
        <div class="card-action">
          <v-btn class="custom-btn-primary" small :disabled="disabled" :ripple="false"
                 @click="addNew = false"
          >
            Cancel
          </v-btn>
          <v-btn class="custom-btn-secondary" :disabled="disabled" :ripple="false" small
                 @click="saveNewItem"
          >
            Save
          </v-btn>
        </div>
      </v-card>
      <div v-if="errorMessage && addNew === true" class="mini-card-custom-error-container">
        {{ errorMessage }}
      </div>
    </v-col>
  </v-row>
</template>
<script>
import swalAlertMixin from '@/mixins/swalAlert.js'
import validator from 'validator'
export default {
  name: 'CommonCard',
  mixins: [swalAlertMixin],
  props: {
    getCardCategory: {
      type: String,
      default: ''
    },
    titleDescription: {
      type: String,
      default: ''
    },
    mainRoute: {
      type: String,
      default: ''
    },
    subRoute: {
      type: String,
      default: ''
    },
    customElement: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    rowData: [],
    disabled: false,
    editMode: false,
    selectedId: 0,
    name: '',
    url: '',
    active: '',
    addNew: false,
    errorMessage: '',
    forDeleteIds: []
  }),
  created () {
    this.getData()
  },
  methods: {
    addItem () {
      this.selectedId = 0
      this.clearInputs()
      this.addNew = true
      this.active = true
      this.errorMessage = ''
    },
    async saveNewItem () {
      if (validator.isEmpty(this.name)) {
        this.errorMessage = `${this.titleDescription} name is required`
        return
      }
      if (validator.isEmpty(this.url) && this.customElement) {
        this.errorMessage = `${this.titleDescription} site is required`
        return
      }
      try {
        this.disabled = true
        await this.$axios.post(this.subRoute, {
          name: this.name,
          url: this.url,
          active: this.active
        })
        this.addNew = false
        this.clearInputs()
        // Fetch updated data
        this.getData()
      } catch (error) {
        this.errorMessage = this.getErrorMessage(error.response)
      }

      this.disabled = false
    },
    editItem (id, name, url, active) {
      this.addNew = false
      this.clearInputs()
      this.errorMessage = ''
      this.name = name
      this.url = url
      this.active = active
      this.selectedId = this.selectedId !== id ? id : 0
    },
    async saveChanges (id) {
      if (validator.isEmpty(this.name)) {
        this.errorMessage = `${this.titleDescription} name is required`
        return
      }
      this.disabled = true
      try {
        await this.$axios.put(`${this.subRoute}/${id}`, {
          name: this.name,
          url: this.url,
          active: this.active
        })
        // Fetch updated data
        this.getData()
        // Reset
        this.selectedId = 0
        this.clearInputs()
      } catch (error) {
        this.errorMessage = this.getErrorMessage(error.response)
      }
      this.disabled = false
    },
    async getData () {
      const rowData = await this.$axios.get(this.mainRoute)
      this.rowData = rowData.data.data
    },
    async continueDeletion (id) {
      this.forDeleteIds.push(id)
      try {
        await this.$axios.delete(this.subRoute, {
          data: {
            ids: this.forDeleteIds
          }
        })
        // Clear for delete
        this.forDeleteIds = []
        // Fetch updated data
        this.getData()
      } catch (error) {
        this.swalDeletionFailed(error)
      }
    },
    clearInputs () {
      this.active = ''
      this.name = ''
      this.url = ''
    }
  }
}
</script>
<style scoped>
.p-left {
  padding-left: 14px;
}
</style>

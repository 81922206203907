<template>
  <v-dialog v-model="show" max-width="716px" persistent @keydown.esc="cancelAction('outside')"
            @click:outside="cancelAction('outside')"
  >
    <v-card class="main-card">
      <CommonPageTitle :title="selectedAction + ' Merchants'" />
      <v-form ref="form" v-model="valid">
        <CommonDetailPipe :items="pipeItems" size="small" :with-switch-view="false" />
        <div class="form-carousel-content">
          <v-row class="custom-row">
            <v-col cols="12" sm="4">
              <label class="custom-label">Status</label>
            </v-col>
            <v-col cols="12" sm="8">
              <label class="custom-radio-label">
                <input v-model="status" type="radio" class="custom-radio" name="status"
                       value="unpaid" checked
                >Unpaid
              </label>
              <label class="custom-radio-label">
                <input v-model="status" type="radio" class="custom-radio" name="status"
                       value="paid"
                >Paid
              </label>
            </v-col>
          </v-row>

          <v-row class="custom-row p-top">
            <v-col cols="12" sm="4">
              <label class="custom-label">Notes</label>
            </v-col>
            <v-col cols="12" sm="8">
              <v-textarea v-model="notes" class="form-control" dense solo />
            </v-col>
          </v-row>
        </div>
        <div class="form-actions">
          <div v-if="errorMessage" class="custom-error-container">
            {{ errorMessage }}
          </div>
          <v-btn class="custom-btn-primary"
                 small
                 :disabled="loading"
                 :ripple="false"
                 @click="cancelAction('inside')"
          >
            {{ cancelButtonText }}
          </v-btn>
          <v-btn class="custom-btn-secondary"
                 :disabled="loading"
                 :loading="loading"
                 :ripple="false"
                 small
                 @click="handleSubmit"
          >
            {{ saveButtonText }}
          </v-btn>
        </div>
      </v-form>

      <CommonProcessIndicator v-if="loading" />
    </v-card>
  </v-dialog>
</template>

<script>

import CommonPageTitle from '@/components/common/CommonPageTitle.vue'
import CommonDetailPipe from '@/components/common/CommonDetailPipe.vue'
import CommonProcessIndicator from '@/components/common/CommonProcessIndicator.vue'
import isNumberMixin from '@/mixins/isNumber.js'
import getErrorMessageMixin from '@/mixins/getErrorMessage.js'
import hasShowMixin from '@/mixins/hasShow.js'
export default {
  name: 'PayablesForm',
  components: {
    CommonPageTitle,
    CommonDetailPipe,
    CommonProcessIndicator
  },
  mixins: [getErrorMessageMixin, isNumberMixin, hasShowMixin],
  props: {
    value: Boolean,
    payableIds: {
      default: null,
      type: Array
    },
    selectedAction: {
      default: 'view',
      type: String
    },
    selectedStatus: {
      default: 'active',
      type: String
    }
  },
  data: () => ({
    selectedFormCarouselIndex: 1,
    cancelButtonText: 'Cancel',
    saveButtonText: 'Save',
    pipeItems: [{ label: 'Payables' }],
    queuedIndex: 0,
    valid: true,
    loading: false,
    errorMessage: '',
    store: '',
    notes: '',
    status: ''

  }),
  methods: {
    async handleSubmit () {
      this.loading = true
      if (this.selectedAction === 'edit') {
        const id = this.payableIds[this.queuedIndex]
        try {
          await this.$axios.put(`/admin/merchant-payables/${id}`, {
            // end point
            status: this.status,
            notes: this.notes

          })
          //
          this.payableIds.length - 1 > this.queuedIndex ? this.getnextData() : this.doneAction()

          this.loading = false
        } catch (error) {
          this.errorMessage = this.getErrorMessage(error.response)
          this.loading = false
        }
      }
    },
    retrieveData () {
      const id = this.payableIds[this.queuedIndex]
      this.getPayablesData(id)
    },
    async getPayablesData (id) {
      this.loading = true
      this.errorMessage = ''
      try {
        const payables = await this.$axios.get(`/admin/merchant-payables/${id}`)
        this.storeName = payables.data.data.merchant.storeName
        this.status = payables.data.data.status
        this.notes = payables.data.data.notes
        this.pipeItems.push({ label: this.storeName + ` (#${payables.data.data.orderId})` })
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    doneAction () {
      this.clearInputs()
      this.$emit('doneAction')
      this.queuedIndex = 0
      this.show = false
    },
    cancelAction (location) {
      if (!this.loading) {
        if (this.selectedAction === 'edit' && this.cancelButtonText === 'Cancel') {
        // Accomodate other checked items when editing
          this.payableIds.length - 1 > this.queuedIndex ? this.getnextData() : this.doneAction()
        } else {
          if (location === 'outside') {
            this.payableIds.length - 1 > this.queuedIndex ? this.getnextData() : this.doneAction()
          }
        }
      }
    },
    getnextData () {
      const id = this.payableIds[this.queuedIndex + 1]
      this.queuedIndex++
      this.getPayablesData(id)
      this.pipeItems.splice(1, 1)
    },
    clearInputs () {
      this.pipeItems.splice(1, 1)
      this.pipeItems[0].label = 'Payables'
      this.$refs.form.resetValidation()
      this.errorMessage = ''
    }
  }
}
</script>
<style scoped>
.p-top {
  padding-top: 28px;
}
</style>

export default {
  data: () => ({
    totalRecords: 0,
    totalPages: 1,
    currentPage: 1,
    paginationAnchorTexts: {
      prev: 'Previous',
      next: 'Next'
    },
    showFrom: 1,
    showTo: 5,
    pageSize: 5,
    selectedEntry: 5
  }),
  watch: {
    currentPage () {
      this.getPageData()
    }
  },
  methods: {
    currentPaginationDisplay () {
      this.currentPage = 1
      this.totalPages = Math.ceil(this.totalRecords / this.selectedEntry)
    }
  }

}

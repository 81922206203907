<template>
  <div>
    <CommonPageTitle title="Logs > Payable log" />
    <PayableLogDataTable />
  </div>
</template>
<script>

import CommonPageTitle from '@/components/common/CommonPageTitle.vue'
import PayableLogDataTable from '@/components/partials/logs/PayableLogDataTable.vue'
export default {
  name: 'MerchantLog',
  components: {
    CommonPageTitle,
    PayableLogDataTable
  }
}
</script>

import axios from 'axios'
import router from './router'

const tokenKey = `${process.env.VUE_APP_LOCALSTORAGE_PREFIX}_token`
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.common.Authorization = localStorage.getItem(tokenKey)
axios.interceptors.response.use((response) => response,
  (error) => {
    if (error.response.status === 401 && localStorage.getItem(tokenKey)) return router.push('/unauthorized')
    return Promise.reject(error)
  })

export default axios

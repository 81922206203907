<template>
  <div v-if="currentAdmin.role === 'super'" class="row-container">
    <v-row>
      <v-col cols="12" md="6">
        <v-card class="main-card">
          <h2 class="item-title">
            Recent Admin Activity
          </h2>
          <div class="list-container">
            <div v-for="item in adminActivities" :key="item.id" class="list-item">
              <p class="item-info" v-html="highlightVerb(item.info)" />
              <p class="activity-date">
                {{ $dayjs(item.updatedAt).format('D MMMM YYYY, HH:mm') }}
              </p>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="main-card">
          <h2 class="item-title">
            Recent Merchant Activity
          </h2>
          <div class="list-container">
            <div v-for="item in merchantActivities" :key="item.id" class="list-item">
              <p class="item-info" v-html="highlightVerb(item.info)" />
              <p class="activity-date">
                {{ $dayjs(item.updatedAt).format('D MMMM YYYY, HH:mm') }}
              </p>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import currentAdminMixin from '@/mixins/currentAdmin.js'
import isActionLogMixin from '@/mixins/isActionLog.js'
export default {
  name: 'DashboardDataSummary',
  mixins: [currentAdminMixin, isActionLogMixin],
  data: () => ({
    adminActivities: [],
    merchantActivities: []
  }),
  created () {
    if (this.currentAdmin.role === 'super') {
      this.getDataActivity()
    }
  },
  methods: {
    async getDataActivity () {
      // Logs
      const [adminActivities, merchantActivities] = await Promise.all([
        this.$axios.get('/admin/logs/admins/', {
          params: {
            perPage: 6,
            currentPage: 1
          }
        }),
        this.$axios.get('/admin/logs/merchants/', {
          params: {
            perPage: 6,
            currentPage: 1
          }
        })
      ])

      this.adminActivities = adminActivities.data.data
      this.merchantActivities = merchantActivities.data.data
    }
  }
}
</script>
<style  scoped>
  .row-container {
    margin-top: 25px !important;
  }

  .v-card {
    color: var(--primary-color);
    width: 684px;
  }

  .v-card .item-title {
    font-family: 'Mollen-Medium', sans-serif;
    font-size: 17px;
    font-weight: normal;
    margin-bottom: 18px;
  }

  .v-card
  .list-container {
    padding-right: 10px;
    margin-bottom: 18px;
  }

  .v-card
  .list-container
  .list-item {
    font-family: 'Mollen-Regular', sans-serif;
    font-size: 17px;
    margin-bottom: 18px;
    vertical-align: middle;
    clear: both;
  }

  .v-card
  .list-container
  .list-item
  .activity-date {
    float: right;
    font-family: 'Mollen-LightItalic', sans-serif;
    font-size: 17px;
    font-style: italic;
  }

  .item-info {
    float: left;
    width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
    white-space: nowrap;

  }

  .item-info >>> .info-highlight {
    color: var(--secondary-color);
  }

  .item-info:hover {
    text-overflow: clip;
    white-space: normal;
    word-break: break-all;
  }

  @media only screen and (max-width: 1010px) {

    .item-info {
      width: 300px;
    }
  }
  @media only screen and (max-width: 955px) {

    .item-info {
      width: 450px;
    }

    .v-card {
      width: 100%;
    }
  }
  @media only screen and (max-width: 790px) {

    .item-info {
      width: 350px;
    }
  }
  @media only screen and (max-width: 740px) {

    .item-info {
      width: 250px;
    }
  }
  @media only screen and (max-width: 640px) {

    .item-info {
      width: 220px;
    }
  }
</style>

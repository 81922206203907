<template>
  <div>
    <v-alert v-if="passwordForceChange" dense type="error" outlined>
      Password change is required to access other pages
    </v-alert>
    <v-card class="main-card">
      <v-form ref="form" v-model="valid">
        <v-row class="custom-row">
          <v-col cols="12" md="2">
            <label class="custom-label">Name:</label>
          </v-col>
          <v-col cols="12" md="10">
            <v-text-field v-model="name" :rules="nameRules" required outlined
                          dense @keyup.enter="validate"
            />
          </v-col>
        </v-row>
        <v-row class="custom-row">
          <v-col cols="12" md="2">
            <label class="custom-label">Email:</label>
          </v-col>
          <v-col cols="12" md="10">
            <v-text-field v-model="email" :rules="emailRules" required outlined
                          dense @keyup.enter="validate"
            />
          </v-col>
        </v-row>
        <v-row class="custom-row">
          <v-col cols="12" md="2">
            <label class="custom-label">Current Password:</label>
          </v-col>
          <v-col cols="12" md="10">
            <v-text-field v-model="currentPassword" type="password" :rules="currentPasswordRules" required
                          outlined dense
                          @keyup.enter="validate"
            />
          </v-col>
        </v-row>
        <v-row class="custom-row">
          <v-col cols="12" md="2">
            <label class="custom-label">New Password:</label>
          </v-col>
          <v-col cols="12" md="10">
            <v-text-field v-model="newPassword" type="password" :rules="newPasswordRules" required
                          outlined dense
                          @keyup.enter="validate"
                          @input="applyNewPwdRelatedRules"
            />
          </v-col>
        </v-row>
        <v-row class="custom-row">
          <v-col cols="12" md="2">
            <label class="custom-label">Confirm New Password:</label>
          </v-col>
          <v-col cols="12" md="10">
            <v-text-field ref="newPasswordConfirm" v-model="newPasswordConfirm" type="password"
                          :rules="newPasswordConfirmRules.concat(newPasswordConfirmationRule)" required outlined dense
                          @keyup.enter="validate"
            />
          </v-col>
        </v-row>
        <v-row class="custom-row">
          <v-col cols="12" md="2">
            <label class="custom-label">IP Whitelist:</label>
          </v-col>
          <v-col cols="12" md="10">
            <v-textarea v-model="ipWhitelist" :rules="ipWhitelistRules" outlined dense
                        @input="applyIPRules"
            />
          </v-col>
        </v-row>
        <div class="form-actions">
          <div v-if="errorMessage" class="custom-error-container">
            {{ errorMessage }}
          </div>
          <v-btn class="custom-btn-primary" small :disabled="loading" :ripple="false"
                 @click="setUserData"
          >
            Revert
          </v-btn>
          <v-btn class="custom-btn-secondary" :disabled="loading" :ripple="false" small
                 @click="validate"
          >
            Save
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import isNumberMixin from '@/mixins/isNumber.js'
import userRulesValidatorMixin from '@/mixins/userRulesValidator.js'
import currentAdminMixin from '@/mixins/currentAdmin.js'
import swalAlertMixin from '@/mixins/swalAlert.js'
import updateUserDataMixin from '@/mixins/updateUserData.js'
import validator from 'validator'

export default {
  name: 'UsersAccountSettingsForm',
  mixins: [swalAlertMixin, isNumberMixin, userRulesValidatorMixin, currentAdminMixin, updateUserDataMixin],
  data: () => ({
    valid: true,
    loading: false,
    errorMessage: '',
    name: '',
    email: '',
    currentPassword: '',
    currentPasswordRules: [
      v => !validator.isEmpty(v) || 'Current Password is required'
    ],
    newPassword: '',
    newPasswordConfirm: '',
    newPasswordRules: [],
    newPasswordConfirmRules: [],
    ipWhitelist: [],
    passwordForceChange: false
  }),
  computed: {
    newPasswordConfirmationRule () {
      return () =>
        this.newPassword === this.newPasswordConfirm || 'Confirm New Password must match'
    }
  },
  async created () {
    await this.updateUserData()
    this.setUserData()
    this.checkPasswordForceChange(this.passwordForceChange)
  },
  methods: {
    checkPasswordForceChange (pwForceChange) {
      if (pwForceChange) {
        this.newPasswordRules.push(v => !validator.isEmpty(v) || 'New Password is required')
      }
    },
    applyNewPwdRelatedRules () {
      if (this.newPassword) {
        this.newPasswordRules.push(v => validator.isStrongPassword(v, this.strongPwOpts) || this.strongPwMsg)
        this.$refs.newPasswordConfirm.validate()
      } else {
        !this.passwordForceChange ? this.newPasswordRules = [] : this.newPasswordRules.splice(0, 0)
        this.$refs.newPasswordConfirm.resetValidation()
      }
    },
    setUserData () {
      this.name = this.currentAdmin.name
      this.email = this.currentAdmin.email
      this.passwordForceChange = this.currentAdmin.pwForceChange
      this.ipWhitelist = this.currentAdmin.ipWhitelist.join('\n')
    },
    validate () {
      this.errorMessage = ''
      if (this.$refs.form.validate()) {
        this.handleSubmit()
      }
    },
    async handleSubmit () {
      try {
        this.loading = true

        await this.$axios.put('/admin/account/update', {
          name: this.name,
          email: this.email,
          password: this.currentPassword,
          newPassword: this.newPassword,
          newPasswordConfirm: this.newPasswordConfirm,
          ipWhitelist: this.ips.filter(val => val)
        })

        await this.updateUserData()
        this.swalSuccessfullyUpdate()

        this.newPassword = ''
        this.newPasswordConfirm = ''
        this.$refs.form.resetValidation()
        this.loading = false

        this.$router.push('/')
      } catch (error) {
        this.errorMessage = this.getErrorMessage(error.response)
        this.loading = false
      }
    }
  }
}
</script>
<style scoped>
.main-card {
  padding-top: 80px;
}

.form-actions .v-btn {
  width: 90px;
  margin-left: 30px !important;
}

.v-application .error--text {
  font-family: 'Mollen-Regular', sans-serif !important;
  font-size: 17px !important;
}
</style>

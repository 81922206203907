<template>
  <div>
    <CommonDetailPipe v-if="currentAdmin.role !== 'accountant'" :items="pipeItems"
                      @switchView="selectedView = $event"
    />
    <v-card class="main-card">
      <CommonDataFilter v-if="selectedView !== 'Accounting'" :status="status" :selected-status="selectedStatus"
                        :action="action" @applyFilter="applyFilter" @applyAction="handleAction"
      />
      <CommonDataFilter v-else :status="status" :selected-status="selectedStatus" @applyFilter="applyFilter" />

      <div class="custom-table">
        <table>
          <thead slot="head">
            <th v-if="selectedView !== 'Accounting'" class="checkbox-col">
              <!-- checkbox -->
            </th>
            <th>
              Store Name <CommonSortIcons v-model="sort.key" default-sort="asc" sort-key="storeName" @applySort="sortBy" />
            </th>
            <th class="a2-col">
              Type <CommonSortIcons v-model="sort.key" sort-key="merchantType" @applySort="sortBy" />
            </th>
            <th>
              Address <CommonSortIcons v-model="sort.key" sort-key="address" @applySort="sortBy" />
            </th>
            <th>
              Contact Person <CommonSortIcons v-model="sort.key" sort-key="contactPerson" @applySort="sortBy" />
            </th>
            <th>
              Contact Email <CommonSortIcons v-model="sort.key" sort-key="contactEmail" @applySort="sortBy" />
            </th>
            <th v-if="selectedView !== 'Accounting'">
              Phone <CommonSortIcons v-model="sort.key" sort-key="contactNumber" @applySort="sortBy" />
            </th>
            <th v-if="selectedView === 'Accounting'">
              Payment Option <CommonSortIcons v-model="sort.key" sort-key="paymentOption" @applySort="sortBy" />
            </th>
            <th v-if="selectedView === 'Accounting'">
              Payment Details <CommonSortIcons v-model="sort.key" sort-key="paymentOptionDetails" @applySort="sortBy" />
            </th>
          </thead>
          <tbody>
            <tr v-for="row in sortedItems" :key="row.id">
              <td v-if="selectedView !== 'Accounting'" class="text-center">
                <input :id="row.id" v-model="checkedItems" :value="row.id" name="merchantId"
                       type="checkbox"
                >
              </td>
              <td>{{ row.storeName }}</td>
              <td align="center">
                <img v-if="row.merchantType === 'business'" class="icon-svg"
                     :src="require('@/assets/images/Icons/SVG/awesome-building.svg')"
                >
                <img v-else class="icon-svg" :src="require('@/assets/images/Icons/SVG/awesome-user-alt.svg')">
              </td>
              <td>
                {{ row.addressLine1 }},
                <template v-if="row.addressLine2">
                  {{ rows.addressLine2 }},
                </template>
                {{ row.city }},
                {{ row.province }},
                {{ row.postalCode }}
              </td>
              <td>{{ row.contactPerson }}</td>
              <td>{{ row.contactEmail }}</td>
              <td v-if="selectedView !== 'Accounting'">
                {{ row.contactNumber }}
              </td>
              <td v-if="selectedView === 'Accounting'">
                {{ row.paymentOption }}
              </td>
              <td v-if="selectedView === 'Accounting'">
                {{ row.paymentOptionDetails }}
              </td>
            </tr>
            <tr v-if="!rowData.length">
              <td class="text-center" colspan="7">
                No Data
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="pagination-container">
        <CommonPaginationDetails :current-page="currentPage" :selected-entry="selectedEntry"
                                 :total-records="totalRecords"
        />
        <CommonPaginationLinks v-if="totalPages" v-model="currentPage" :page-count="totalPages" />
      </div>
    </v-card>

    <MerchantsForm ref="merchantFormRef" v-model="showDialog" :merchant-ids="checkedItems"
                   :selected-action="selectedAction" :selected-status="selectedStatus" @doneAction="getAllData"
    />
  </div>
</template>
<script>
import CommonDetailPipe from '@/components/common/CommonDetailPipe.vue'
import CommonDataFilter from '@/components/common/CommonDataFilter.vue'
import CommonPaginationDetails from '@/components/common/CommonPaginationDetails.vue'
import CommonPaginationLinks from '@/components/common/CommonPaginationLinks.vue'
import MerchantsForm from '@/components/partials/merchants/MerchantsForm.vue'
import currentAdminMixin from '@/mixins/currentAdmin.js'
import isPaginationMixin from '@/mixins/isPagination.js'
import swalAlertMixin from '@/mixins/swalAlert.js'
import CommonSortIcons from '@/components/common/CommonSortIcons.vue'
import tableSorterMixin from '@/mixins/tableSorter.js'

export default {
  name: 'MerchantsDataTable',
  components: {
    CommonDetailPipe,
    CommonDataFilter,
    CommonPaginationDetails,
    CommonPaginationLinks,
    MerchantsForm,
    CommonSortIcons
  },
  mixins: [currentAdminMixin, isPaginationMixin, swalAlertMixin, tableSorterMixin],
  data: () => ({
    pipeItems: ['General', 'Accounting'],
    search: '',
    rowData: [],
    showDialog: false,
    showDelCount: true,
    checkedItems: [],
    action: ['view', 'add', 'edit', 'delete'],
    selectedAction: 'view',
    status: [
      { type: 'active', count: 0 },
      { type: 'pending', count: 0 },
      { type: 'suspended', count: 0 },
      { type: 'rejected', count: 0 }
    ],
    selectedStatus: 'active',
    selectedView: 'General',
    paymentOptions: []
  }),
  watch: {
    search () {
      this.getTotalData()
    }
  },
  created () {
    if (this.currentAdmin.role === 'accountant') this.selectedView = 'Accounting'
    this.getAllData()
    this.getPaymentOptions()
  },
  methods: {
    applyFilter (selectedStatus, selectedEntry, search) {
      this.search = search
      this.selectedStatus = selectedStatus
      this.selectedEntry = selectedEntry
      this.getPageData()
      this.totalRecords = this.status[this.status.findIndex(s => s.type === this.selectedStatus)].count
      this.currentPaginationDisplay()
    },
    getAllData () {
      this.getTotalData()
      this.getPageData()
    },
    async getTotalData () {
      this.checkedItems = []
      const [active, pending, suspended, rejected] = await Promise.all([
        ...this.status.map(status => this.$axios.get('/admin/merchants/count', {
          params: {
            search: this.search,
            status: status.type
          }
        }))
      ])
      const total = { active, pending, suspended, rejected }
      this.status.forEach(status => { status.count = total[status.type].data.data.count })
      this.totalRecords = total[this.selectedStatus].data.data.count
      this.currentPaginationDisplay()
    },
    async getPageData () {
      const response = await this.$axios.get('/admin/merchants', {
        params: {
          search: this.search,
          status: this.selectedStatus,
          perPage: this.selectedEntry,
          currentPage: this.currentPage,
          sort: 'name'
        }
      })
      const modifiedData = response.data.data.map(entry => {
        return {
          ...entry,
          contactPerson: entry.contact.person,
          contactEmail: entry.contact.email,
          contactNumber: entry.contact.number,
          addressLine1: entry.address.addressLine1,
          addressLine2: entry.address.addressLine,
          city: entry.address.city,
          province: entry.address.province,
          postalCode: entry.address.postalCode,
          paymentOption: this.getPaymentOptionName(entry.paymentOption.id),
          paymentOptionDetails: entry.paymentOption.details
        }
      })
      this.rowData = modifiedData
    },
    async getPaymentOptions () {
      const response = await this.$axios.get('/admin/merchant-payment-options')
      this.paymentOptions = response.data.data
    },
    getPaymentOptionName (id = null) {
      const payOption = this.paymentOptions.find(opt => opt.id === id)
      return payOption ? payOption.name : 'N/A'
    },
    handleAction (action) {
      this.selectedAction = action

      if (action === 'add') {
        this.showDialog = true
      }

      if (action === 'edit' && this.checkedItems.length) {
        // Fill inputs with the details
        this.$refs.merchantFormRef.retrieveData()

        // Open Dialog
        this.showDialog = true
      }

      if (action === 'delete' && this.checkedItems.length) {
        // Open confirmation box
        this.swalConfimationDelete()
      }
    },
    async deleteData (myids) {
      try {
        await this.$axios.delete('/admin/merchants', {
          data: {
            ids: myids
          }
        })
        this.swalSuccessfullyDelete(myids)

        // Show updated data
        this.getAllData()

        // Clear checked merchant after delete
        this.checkedItems = []
      } catch (error) {
        this.swalDeletionFailed(error)
      }
    }
  }
}
</script>

<style scoped>
.a1-col {
  width: 75px;
}

.a2-col {
  width: 90px;
}
</style>

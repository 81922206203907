<template>
  <div>
    <CommonPageTitle title="Support > Merchant FAQs" />
    <CommonDetailPipe :items="pipeItems" @switchView="selectedView = $event" />
    <component :is="currentComponent" />
  </div>
</template>
<script>

import CommonPageTitle from '@/components/common/CommonPageTitle.vue'
import CommonDetailPipe from '@/components/common/CommonDetailPipe.vue'
import MerchantFAQs from '@/components/partials/support/merchant/FAQs.vue'
import MerchantFAQCategories from '@/components/partials/support/merchant/FAQCategories.vue'

export default {
  name: 'MerchantFAQIndex',
  components: {
    CommonPageTitle,
    CommonDetailPipe
  },
  data: () => ({
    selectedView: 'FAQs',
    pipeItems: ['FAQs', 'Categories']
  }),
  computed: {
    currentComponent () {
      return this.selectedView === 'FAQs' ? MerchantFAQs : MerchantFAQCategories
    }
  }
}
</script>

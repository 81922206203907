<template>
  <div>
    <v-app id="app">
      <TheSideNavigation v-model="drawer" />
      <TheTopNavigation @applyToggle="toggleSideNav" />
      <v-main>
        <div class="main">
          <router-view />
        </div>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import TheTopNavigation from '@/components/TheTopNavigation.vue'
import TheSideNavigation from '@/components/TheSideNavigation.vue'
import updateUserDataMixin from '@/mixins/updateUserData.js'
import currentAdminMixin from '@/mixins/currentAdmin.js'
export default {
  name: 'LayoutPanel',
  components: {
    TheTopNavigation,
    TheSideNavigation
  },
  mixins: [updateUserDataMixin, currentAdminMixin],
  data () {
    return {
      drawer: null,
      timer: null
    }
  },
  watch: {
    $route () {
      this.updateUserData()
    }
  },
  async created () {
    await this.updateUserData()
    this.timer = setInterval(() => this.updateUserData(), 60000) // Refresh user data every 60 seconds
  },
  destroyed () {
    clearInterval(this.timer)
  },
  methods: {
    toggleSideNav () {
      this.drawer = !this.drawer
    }
  }
}
</script>

<style scoped>
#app {
  background: var(--main-bg-color);
}

.main {
  margin-top: 25px;
  padding: 50px;
}

@media only screen and (max-width: 600px) {

  .main {
    margin-top: 40px;
    padding: 20px;
  }
}
</style>

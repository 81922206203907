<template>
  <div>
    <v-card class="main-card">
      <CommonDataFilter class="custom-filter-alignment" :action="action" :auto-refresh="true" :custom-search="true"
                        @applyAction="handleAction"
                        @applyFilter="applyFilter"
                        @applyAutoRefresh="getPageData"
      />
      <div class="custom-table">
        <table>
          <thead slot="head">
            <th class="checkbox-col">
              <!-- checkbox -->
            </th>
            <th>
              ID <CommonSortIcons v-model="sort.key" sort-key="id" @applySort="sortBy" />
            </th>
            <th>
              Status <CommonSortIcons v-model="sort.key" sort-key="status" @applySort="sortBy" />
            </th>
            <th>
              Department <CommonSortIcons v-model="sort.key" sort-key="departmentName" @applySort="sortBy" />
            </th>
            <th>
              Merchant <CommonSortIcons v-model="sort.key" sort-key="storeName" @applySort="sortBy" />
            </th>
            <th>
              Subject <CommonSortIcons v-model="sort.key" sort-key="subject" @applySort="sortBy" />
            </th>
            <th class="no-border">
              <!-- Button Details -->
            </th>
          </thead>
          <tbody>
            <tr v-for="row in sortedItems" :key="row.id">
              <td class="text-center">
                <input :id="row.id" v-model="checkedItems" :value="row.id" name="ticketId"
                       type="checkbox"
                >
              </td>
              <td>#{{ row.id }}</td>
              <td>{{ row.status }}</td>
              <td>{{ row.departmentName }}</td>
              <td>{{ row.storeName }}</td>
              <td>{{ row.subject }}</td>
              <td class="text-center no-border">
                <v-btn class="custom-btn-secondary" :ripple="false" small @click="viewMessage(row.id)">
                  View Message
                </v-btn>
              </td>
            </tr>
            <tr v-if="rowData.length === 0">
              <td class="text-center" colspan="6">
                No data
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pagination-container custom-pagination-alignment">
        <CommonPaginationDetails :current-page="currentPage" :selected-entry="selectedEntry" :total-records="totalRecords" />
        <CommonPaginationLinks v-if="totalPages" v-model="currentPage" :page-count="totalPages" />
      </div>
    </v-card>
    <TicketMessage ref="ticketMessageRef" v-model="showDialogTicketMessage" />
    <TicketFormEdit ref="ticketFormEditRef" v-model="showDialogFormEdit" :ticket-ids="checkedItems"
                    :selected-action="selectedAction" :selected-status="selectedStatus" @doneAction="getTotalData"
    />
    <TicketFormAdd ref="ticketFormAddRef" v-model="showDialogFormAdd"
                   :selected-action="selectedAction" :selected-status="selectedStatus" @doneAction="getTotalData"
    />
  </div>
</template>
<script>
import CommonDataFilter from '@/components/common/CommonDataFilter.vue'
import CommonPaginationDetails from '@/components/common/CommonPaginationDetails.vue'
import CommonPaginationLinks from '@/components/common/CommonPaginationLinks.vue'
import CommonSortIcons from '@/components/common/CommonSortIcons.vue'
import TicketMessage from '@/components/partials/support/tickets/TicketMessage.vue'
import TicketFormEdit from '@/components/partials/support/tickets/TicketFormEdit.vue'
import TicketFormAdd from '@/components/partials/support/tickets/TicketFormAdd.vue'
import isPaginationMixin from '@/mixins/isPagination.js'
import swalAlertMixin from '@/mixins/swalAlert.js'
import tableSorterMixin from '@/mixins/tableSorter.js'

export default {
  name: 'TicketsDataTable',
  components: {
    CommonDataFilter,
    CommonPaginationDetails,
    CommonPaginationLinks,
    CommonSortIcons,
    TicketMessage,
    TicketFormEdit,
    TicketFormAdd
  },
  mixins: [isPaginationMixin, swalAlertMixin, tableSorterMixin],
  data: () => ({
    search: '',
    rowData: [],
    departments: [],
    checkedItems: [],
    action: ['view', 'add', 'edit', 'delete'],
    selectedAction: 'view',
    selectedStatus: '',
    showDialogFormEdit: false,
    showDialogFormAdd: false,
    showDialogTicketMessage: false
  }),
  created () {
    this.applyFilter(this.selectedStatus, this.selectedEntry, this.search)
  },
  methods: {
    applyFilter (selectedStatus, selectedEntry, search) {
      this.search = search
      this.selectedStatus = selectedStatus
      this.selectedEntry = selectedEntry
      this.getTotalData()
    },
    async getTotalData () {
      this.checkedItems = []
      const rowCount = await this.$axios.get('/admin/support/tickets/count', {
        params: {
          search: this.search
        }
      })
      this.totalRecords = rowCount.data.data.count
      this.currentPaginationDisplay()
      this.getPageData()
    },
    async getPageData () {
      const [departments, response] = await Promise.all([
        this.$axios.get('/admin/support/departments'),
        this.$axios.get('/admin/support/tickets', {
          params: {
            search: this.search,
            status: this.selectedStatus,
            perPage: this.selectedEntry,
            currentPage: this.currentPage,
            sort: 'desc'
          }
        })
      ])
      this.departments = departments.data.data
      const modifiedData = response.data.data.map(entry => {
        return {
          ...entry,
          departmentName: this.getDepartmentName(entry.department)
        }
      })
      this.rowData = modifiedData
    },
    viewMessage (id) {
      this.$refs.ticketMessageRef.getTicketMessage(id)
      this.showDialogTicketMessage = true
    },
    handleAction (action) {
      this.selectedAction = action
      if (action === 'add') {
        this.$refs.ticketFormAddRef.retrieveData()
        this.showDialogFormAdd = true
      }

      if (action === 'edit' && this.checkedItems.length) {
        this.$refs.ticketFormEditRef.retrieveData()
        this.showDialogFormEdit = true
      }

      if (action === 'delete' && this.checkedItems.length) {
        this.swalConfimationDelete()
      }
    },
    getDepartmentName (id) {
      const dept = this.departments.find(dept => dept.id === id)
      return dept.name
    },
    async deleteData (myids) {
      try {
        await this.$axios.delete('/admin/support/tickets', {
          data: {
            ids: myids
          }
        })
        this.swalSuccessfullyDelete(myids)

        // Show updated data
        this.getTotalData()

        this.checkedItems = []
      } catch (error) {
        this.swalDeletionFailed(error)
      }
    }
  }
}
</script>
<style scoped>

.no-border {
  padding-left: 25px;
}

.custom-btn-secondary {
  width: 150px;
  margin-left: 20px;
}

.custom-filter-alignment {
  margin-right: 227px
}

.custom-pagination-alignment {
  margin-right: 220px
}

@media only screen and (max-width: 600px) {

  .custom-filter-alignment, .custom-pagination-alignment {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 600px) {

  .custom-filter-alignment, .custom-pagination-alignment {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) {

  .custom-filter-alignment, .custom-pagination-alignment {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 992px) {

  .custom-filter-alignment, .custom-pagination-alignment {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 1200px) {

  .custom-filter-alignment {
    margin-right: 227px;
  }

  .custom-pagination-alignment {
    margin-right: 220px
  }
}

</style>

<template>
  <v-dialog v-model="show" max-width="1400px" scrollable>
    <v-card class="main-card">
      <v-form>
        <h1 class="custom-header">
          Order #{{ orderId }} from {{ store }}
        </h1>
        <h2 class="custom-sub-header">
          Note: {{ notes }}
        </h2>
        <hr class="custom-divider">
        <div class="custom-header-content">
          <table class="table">
            <thead>
              <tr>
                <th class="col p-left">
                  Item Name
                </th>
                <th class="col">
                  Quantity
                </th>
                <th class="col">
                  Unit Price
                </th>
                <th class="col">
                  Marked Up Rate
                </th>
              </tr>
            </thead>
          </table>
        </div>
      </v-form>
      <div class="scroll-container">
        <ul>
          <li v-for="item in items" :key="item.items">
            <div>
              <v-card class="item-content">
                <tbody>
                  <tr>
                    <td class="col-items">
                      {{ item.title }}
                    </td>
                    <td class="col-quantity">
                      {{ item.quantity }}
                    </td>
                    <td class="col-price">
                      {{ item.unitPrice.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' }) }}
                    </td>
                    <td class="col-rate">
                      {{ item.deductionRate * 100 }}%
                    </td>
                  </tr>
                  <tr>
                    <td class="seperator" />
                  </tr>
                  <tr>
                    <td />
                    <td />
                    <td class="col-footer">
                      Price Subtotal
                    </td>
                    <td class="col-sub-footer">
                      {{ (item.quantity * item.unitPrice).toLocaleString('en-PH', { style: 'currency', currency: 'PHP' }) }}
                    </td>
                  </tr>
                  <tr>
                    <td />
                    <td />
                    <td class="col-footer">
                      Deduction
                    </td>
                    <td class="col-sub-footer">
                      {{ item.deductionSubtotal.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' }) }}
                    </td>
                  </tr>
                  <tr>
                    <td />
                    <td />
                    <td class="col-footer">
                      Payable Subtotal
                    </td>
                    <td class="col-sub-footer">
                      {{ ((item.quantity * item.unitPrice) - (item.deductionSubtotal)).toLocaleString('en-PH', { style: 'currency', currency: 'PHP' }) }}
                    </td>
                  </tr>
                </tbody>
              </v-card>
            </div>
          </li>
        </ul>
      </div>
      <v-form>
        <div class="custom-footer-content">
          <div class="container">
            <div class="row">
              <div class="col-md-4 offset-md-7 custom-text-subtotal">
                Payable Total <span class="custom-sub-total">
                  {{ subTotal.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' }) }}
                </span>
              </div>
            </div>
          </div>
          <div class="custom-button-content">
            <v-btn class="custom-btn-primary" :ripple="false" small block
                   @click="exitModal"
            >
              Exit
            </v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import isNumberMixin from '@/mixins/isNumber.js'
import getErrorMessageMixin from '@/mixins/getErrorMessage.js'
import hasShowMixin from '@/mixins/hasShow.js'
export default {
  name: 'PayablesDetailsForm',
  mixins: [getErrorMessageMixin, isNumberMixin, hasShowMixin],
  props: {
    value: Boolean,
    payableIds: {
      default: null,
      type: String
    }
  },
  data: () => ({
    pipeItems: [{ label: 'Payables' }],
    valid: true,
    loading: false,
    errorMessage: '',
    store: '',
    orderId: '',
    notes: '',
    subTotal: '',
    items: []

  }),
  methods: {
    exitModal () {
      this.show = false
    },
    async getPayablesData (getPayablesData) {
      this.loading = true
      this.errorMessage = ''
      try {
        const payables = await this.$axios.get(`/admin/merchant-payables/${getPayablesData}`)
        this.store = payables.data.data.merchant.storeName
        this.orderId = payables.data.data.orderId
        this.notes = payables.data.data.notes
        this.items = payables.data.data.items
        this.subTotal = payables.data.data.total
        this.loading = false
      } catch (error) {
        this.errorMessage = this.getErrorMessage(error.response)
        this.loading = false
      }
    }

  }
}
</script>
<style  scoped>
.main-card {
  font-family: 'Mollen-Regular', sans-serif;
  font-size: 17px;
  color: var(--primary-color);
  padding-top: 35px;
}

.p-top {
  padding-top: 28px;
}

.custom-header {
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: center;
  font-family: 'Mollen-SemiBold', sans-serif;
  font-size: 28px;
  color: var(--primary-color);
}

.custom-sub-header {
  text-align: center;
  font-family: 'Mollen-SemiBold', sans-serif;
  font-size: 18px;
  font-style: italic;
  color: var(--primary-color);
}

.custom-divider {
  max-width: 480pt;
  margin: auto;
  margin-top: 25px;
  border-top: 2px solid var(--secondary-color);
  border-radius: 5px;
}

.col {
  width: 400pt;
  font-family: 'Mollen-SemiBold', sans-serif;
  font-size: 14px;
  color: var(--secondary-color);
}

.p-left {
  padding-left: 16px;
}

.table {
  max-width: 1200pt;
}

.scroll-container {
  overflow: auto;
  margin-left: 0px;
}

.scroll-container::-webkit-scrollbar {
  width: 10px;
}

.scroll-container::-webkit-scrollbar,
.scroll-container::-webkit-scrollbar-track {
  border-radius: 5px;
  background: 1px solid #707070;
  background-color: #ebedf5;
}

.scroll-container::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: var(--primary-color);
}

.custom-text-subtotal {
  font-family: 'Mollen-SemiBold', sans-serif;
  font-size: 25px;
  padding: 34px 0px;
  padding-left: 27px;
  color: var(--secondary-color);
}

.custom-footer-content {
  box-shadow: 0 -4px 3px -3px  rgba(0, 0, 0, 0.3);
  height: 120px;
  margin-top: 25px !important;
}

.custom-header-content {
  margin-top: 40px !important;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.3);
  margin-bottom:15px
}

.item-content {
  height: 230px;
  max-width: 1090px;
  margin: auto;
  padding-top: 25px;
  background-color: #ebedf5;
  border-radius: 5px
}

ul > li {
  list-style-type: none;
  margin-bottom: 15px;
}

.col-items {
  width: 350px;
  padding-left: 20px
}

.col-quantity {
  width: 330px;
}

.col-price {
  width: 240px
}

.col-rate {
  width: 135px;
  text-align: right;
}

.col-footer {
  width: 240px;
  color: var(--secondary-color);
  height: 30px;
}

.col-sub-footer {
  width: 135px;
  text-align: right;
}

.seperator {
  height: 55px;
}

.custom-sub-total {
  color: var(--primary-color);
  float: right;
  padding-right:12px;
}

.custom-button-content {
  max-width: 1090px;
  margin: auto;
}
</style>

<template>
  <div>
    <v-navigation-drawer v-model="drawer" app>
      <div class="logo">
        <router-link to="/">
          <img src="@/assets/images/logo.png" alt="ANEC Admin">
        </router-link>
      </div>
      <ul class="main-menu">
        <li v-for="(item, index) in navItems" :key="item.link">
          <router-link v-if="item.restriction.includes(currentAdmin.role)" :to="item.link" :exact="item.link === '/'"
                       :event="item.subItems ? null : 'click'" @click.native="item.subItems ? toggle(index) : {}"
          >
            <img class="icon-svg" :src="require('@/assets/images/Icons/SVG/' + item.icon)">
            {{ item.name }}

            <img v-if="item.subItems" :src="require('@/assets/images/Icons/SVG/chevron-right.svg')"
                 :class="['sub-icon-svg', { 'rotate' : expanded === index }]"
            >
          </router-link>

          <template v-if="item.subItems">
            <ul v-show="expanded === index">
              <li v-for="subItem in item.subItems" :key="subItem.link">
                <router-link :to="subItem.link">
                  {{ subItem.name }}
                </router-link>
              </li>
            </ul>
          </template>
        </li>
      </ul>
    </v-navigation-drawer>
  </div>
</template>

<script>
import currentAdminMixin from '@/mixins/currentAdmin.js'
import updateUserDataMixin from '@/mixins/updateUserData.js'
export default {
  name: 'TheSideNavigation',
  mixins: [currentAdminMixin, updateUserDataMixin],
  props: {
    value: {
      type: Boolean,
      default: null
    }
  },
  data: () => ({
    expanded: null,
    navItems: [
      {
        name: 'Dashboard',
        link: '/',
        icon: 'feather-home.svg',
        restriction: ['super', 'admin', 'accountant']
      },
      {
        name: 'Users',
        link: '/users',
        icon: 'feather-users.svg',
        restriction: ['super']
      },
      {
        name: 'Merchants',
        link: '/merchants',
        icon: 'feather-smile.svg',
        restriction: ['super', 'admin', 'accountant']
      },
      {
        name: 'Shipping',
        link: '/shipping',
        icon: 'feather-truck.svg',
        restriction: ['super', 'admin']
      },
      {
        name: 'Orders',
        link: '/orders',
        icon: 'feather-shopping-cart.svg',
        restriction: ['super', 'admin', 'accountant']
      },
      {
        name: 'Payables',
        link: '/payables',
        icon: 'peso.svg',
        restriction: ['super', 'accountant']
      },
      {
        name: 'Pay Options',
        link: '/payment-options',
        icon: 'PaymentOptions.svg',
        restriction: ['super', 'admin']
      },
      {
        name: 'Support',
        link: '/support',
        icon: 'feather-paperclip.svg',
        restriction: ['super', 'admin'],
        subItems: [
          {
            name: 'Merchant FAQs',
            link: '/support/merchant-faq'
          },
          {
            name: 'Departments',
            link: '/support/departments'
          },
          {
            name: 'Tickets',
            link: '/support/tickets'

          }
        ]
      },
      {
        name: 'Logs',
        link: '/logs',
        icon: 'feather-book-open.svg',
        restriction: ['super'],
        subItems: [
          {
            name: 'Admin Log',
            link: '/logs/admin'
          },
          {
            name: 'Merchant Log',
            link: '/logs/merchant'
          },
          {
            name: 'Payable Log',
            link: '/logs/payable'
          }
        ]
      }
    ]
  }),
  computed: {
    // Toggle Navigation Drawer
    drawer: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    toggle (index) {
      this.expanded === index ? this.expanded = null : this.expanded = index
    }
  }

}
</script>

<style scoped>
.v-navigation-drawer {
  background: var(--menu-bg-color) !important;
}

.logo {
  background: var(--primary-color);
  width: 67px;
  height: 67px;
  border-radius: 50%;
  padding: 8px;
  margin: auto;
  text-align: center;
}

.logo img {
  padding-top: 13px;
  width: 35px;
}

nav a {
  color: var(--sidebar-color);
  text-decoration: none;
  transition: 0.2s ease-in-out;
}

a.router-link-exact-active,
a.router-link-active,
a:hover {
  color: #fff;
}

.main-menu {
  font-family: 'Bolt-Regular', sans-serif ;
  list-style: none;
  margin-top: 20px;
  padding: 20px;
}

.main-menu > li {
  margin-bottom: 25px;
}

.main-menu > li > a {
  width: 100%;
  display: inline-block;
  border-radius: 10px;
  font-size: 22px;
  padding: 10px 15px;
}

.main-menu > li > ul {
  font-size: 16px;
  margin: 10px 0 0 35px;
}

.main-menu > li > ul > li {
  margin-bottom: 12px;
  list-style: none;
}

.main-menu > li > ul > li:last-child {
  margin: 0;
}

.main-menu > li > a.router-link-exact-active,
.main-menu > li > a.router-link-active,
.main-menu > li > a:hover {
  background: var(--secondary-color);
}

.icon-svg {
  display: inline-block;
  margin-right: 15px;
  width: 24px;
}

.sub-icon-svg {
  float: right;
  width: 19px;
  height: 31px;
}

.icon-svg,
.sub-icon-svg {
  vertical-align: middle;
  transition: 0.2s ease-in-out;
}

.router-link-exact-active .icon-svg,
.router-link-active .icon-svg,
.router-link-active .sub-icon-svg,
.main-menu > li > a:hover .icon-svg,
.main-menu > li > a:hover .sub-icon-svg {
  filter: brightness(0) invert(1);
}

.rotate {
  transform: rotate(-90deg);
}

nav >>> .v-navigation-drawer__content {
  scrollbar-width: none;
  padding: 20px 0 !important;
}

nav >>> .v-navigation-drawer__content::-webkit-scrollbar {
  width: 0;
  height: 0;
}
</style>

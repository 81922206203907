<template>
  <v-row>
    <v-col v-for="item in items" :key="item.slug" cols="12" sm="3">
      <v-card class="main-card" :ripple="false" @click="$router.push(item.link)">
        <div class="count">
          {{ item.count }}
        </div>
        <div class="description">
          {{ item.description }}
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import currentAdminMixin from '@/mixins/currentAdmin.js'
export default {
  name: 'DashboardDataSummary',
  mixins: [currentAdminMixin],
  data: () => ({
    items: []
  }),
  created () {
    switch (this.currentAdmin.role) {
      case 'admin': {
        this.getAdminSummary()
        break
      }
      case 'accountant': {
        this.getAccountantSummary()
        break
      }
      default: {
        this.getSuperAdminSummary()
        break
      }
    }
  },
  methods: {

    async getSuperAdminSummary () {
      const [tickets, merchants, couriers, users] = await Promise.all([
        this.$axios.get('/admin/support/tickets/count', {
          params: {
            status: 'open'
          }
        }),
        this.$axios.get('/admin/merchants/count'),
        this.$axios.get('/admin/couriers/count'),
        this.$axios.get('/admin/admins/count')
      ])

      const ticketsCount = tickets.data.data.count
      this.items.push(
        {
          description: 'open tickets',
          slug: 'tickets',
          count: ticketsCount,
          link: '/support/tickets'
        }
      )

      const merchantsCount = merchants.data.data.count
      this.items.push(
        {
          description: 'total merchants',
          slug: 'merchants',
          count: merchantsCount,
          link: '/merchants'
        }
      )

      const couriersCount = couriers.data.data.count
      this.items.push(
        {
          description: 'total couriers',
          slug: 'couriers',
          count: couriersCount,
          link: '/shipping'
        }
      )

      const usersCount = users.data.data.count
      this.items.push(
        {
          description: 'total users',
          slug: 'users',
          count: usersCount,
          link: '/users'
        }
      )
    },

    async getAdminSummary () {
      const [tickets, merchants, couriers] = await Promise.all([
        this.$axios.get('/admin/support/tickets/count', {
          params: {
            status: 'open'
          }
        }),
        this.$axios.get('/admin/merchants/count'),
        this.$axios.get('/admin/couriers/count')
      ])

      const ticketsCount = tickets.data.data.count
      this.items.push(
        {
          description: 'open tickets',
          slug: 'tickets',
          count: ticketsCount,
          link: '/support/tickets'
        }
      )

      const merchantsCount = merchants.data.data.count
      this.items.push(
        {
          description: 'total merchants',
          slug: 'merchants',
          count: merchantsCount,
          link: '/merchants'
        }
      )

      const couriersCount = couriers.data.data.count
      this.items.push(
        {
          description: 'total couriers',
          slug: 'couriers',
          count: couriersCount,
          link: '/shipping'
        }
      )
    },
    async getAccountantSummary () {
      const [merchants, paid, unpaid] = await Promise.all([
        this.$axios.get('/admin/merchants/count'),
        this.$axios.get('/admin/orders/count', {
          params: {
            paymentStatus: 'paid'
          }
        }),
        this.$axios.get('/admin/orders/count', {
          params: {
            paymentStatus: 'unpaid'
          }
        })
      ])

      const merchantsCount = merchants.data.data.count
      this.items.push(
        {
          description: 'total merchants',
          slug: 'merchants',
          count: merchantsCount,
          link: '/merchants'
        }
      )

      const paidOrders = paid.data.data.count
      this.items.push(
        {
          description: 'paid orders',
          slug: 'paid-orders',
          count: paidOrders,
          link: '/orders'
        }
      )

      const unpaidOrders = unpaid.data.data.count
      this.items.push(
        {
          description: 'unpaid orders',
          slug: 'unpaid-orders',
          count: unpaidOrders,
          link: '/orders'
        }
      )
    }
  }
}
</script>
<style  scoped>
  .main-card {
    width: 292px;
    height: 191px;
    padding: 30px 10px;
    cursor: pointer;
  }

  .count {
    text-align: center;
    font-family: 'Mollen-Semibold', sans-serif;
    font-size: 50px;
    color: var(--secondary-color);
  }

  .description {
    text-align: center;
    font-family: 'Mollen-Regular', sans-serif;
    font-size: 28px;
    color: var(--primary-color);
  }

  @media only screen and (min-width: 360px) and (max-width: 600px) {

    .main-card {
      width: 100%;
    }
  }
  @media only screen and (max-width: 820px) {

    .count {
      font-size: 40px;
    }

    .description {
      font-size: 23px;
    }
  }
</style>

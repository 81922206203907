<template>
  <v-dialog v-model="show" max-width="625px" scrollable persistent
            @keydown.esc="exitDialog" @click:outside="exitDialog"
  >
    <v-card class="main-card">
      <v-row class="ticket-header-info" no-gutters>
        <v-col cols="12" sm="2">
          From
        </v-col>
        <v-col cols="12" sm="10">
          {{ from }}
        </v-col>
      </v-row>
      <v-row class="ticket-header-info" no-gutters>
        <v-col cols="12" md="2">
          Subject
        </v-col>
        <v-col cols="12" md="10">
          {{ subject }}
        </v-col>
      </v-row>
      <div class="scroll-container">
        <div class="ticket-message">
          {{ message }}
        </div>
        <ul class="ticket-reply-logs">
          <li v-for="(reply, index) in replies" :key="reply.id" :class="['reply', `reply-${reply.user.role}`]">
            <div v-if="index">
              <p class="reply-info">
                <span class="date">{{ $dayjs(reply.createdAt).format('D MMMM YYYY, hh:mm A') }}</span>
                <span class="name">{{ reply.user.name }}</span>
              </p>
              <v-card class="message-bubble">
                {{ reply.message }}
              </v-card>
            </div>
          </li>
        </ul>
        <p v-if="loading" class="text-center">
          {{ loadingMessage }}
        </p>
      </div>
      <div class="action">
        <div v-if="newReply" class="message-box">
          <textarea-autosize
            v-model="replyMessage"
            placeholder="Type your message here..."
            :max-height="350"
            :min-height="30"
            :class="{ 'textarea-force-height' : empty }"
          />
          <button @click="sendReply">
            <img :src="require('@/assets/images/Icons/SVG/material-send.svg')">
          </button>
        </div>
        <v-btn v-else class="custom-btn-secondary" :ripple="false" small
               block @click="newReply = true"
        >
          Reply
        </v-btn>
        <div v-if="errorMessage" class="custom-error-container text-center">
          {{ errorMessage }}
        </div>
        <v-btn class="custom-btn-primary" :ripple="false" small block
               @click="exitDialog"
        >
          Exit
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import getErrorMessageMixin from '@/mixins/getErrorMessage.js'
import hasShowMixin from '@/mixins/hasShow.js'
import validator from 'validator'
export default {
  name: 'TicketMessage',
  mixins: [getErrorMessageMixin, hasShowMixin],
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    from: '',
    subject: '',
    message: '',
    replies: [],
    loading: false,
    loadingMessage: 'Loading replies...',
    newReply: false,
    replyMessage: '',
    ticketId: '',
    errorMessage: '',
    empty: true,
    emptyInptOpts: {
      ignore_whitespace: true
    }
  }),
  computed: {
    passwordConfirmationRule () {
      return () =>
        this.password === this.passwordConfirm || 'Password must match'
    }
  },
  watch: {
    replyMessage () {
      if (validator.isEmpty(this.replyMessage)) {
        this.empty = true
      } else {
        this.empty = false
      }
    }
  },
  methods: {
    async getTicketMessage (id) {
      this.loading = true
      const [ticket, replies] = await Promise.all([
        this.$axios.get(`/admin/support/tickets/${id}`),
        this.$axios.get(`/admin/support/tickets/${id}/replies`)
      ])
      const merchantEmail = await this.$axios.get(`/admin/merchants/${ticket.data.data.merchantId}`)
      this.ticketId = id
      this.from = merchantEmail.data.data.email
      this.subject = ticket.data.data.subject
      this.message = replies.data.data[0].message
      this.replies = replies.data.data
      this.loading = false
      this.loadingMessage = ''
    },
    async sendReply () {
      if (validator.isEmpty(this.replyMessage, this.emptyInptOpts)) {
        this.errorMessage = 'Your message is too short.'
        return
      }

      try {
        this.loading = true
        this.loadingMessage = 'Sending...'
        await this.$axios.post(`/admin/support/tickets/${this.ticketId}/replies`, {
          message: this.replyMessage
        })
        this.getTicketMessage(this.ticketId)
        this.clearInput()
      } catch (error) {
        this.errorMessage = this.getErrorMessage(error.response)
        this.loading = false
        this.loadingMessage = ''
      }
    },
    clearInput () {
      this.replyMessage = ''
      this.errorMessage = ''
    },
    exitDialog () {
      if (!this.loading) {
        this.clearInput()
        this.show = false
        this.newReply = false
        this.errorMessage = ''
      }
    }
  }
}
</script>
<style  scoped>
.main-card {
  font-family: 'Mollen-Regular', sans-serif;
  font-size: 17px;
  color: var(--primary-color);
  padding-top: 35px;
}

.scroll-container {
  overflow: auto;
}

.scroll-container::-webkit-scrollbar {
  width: 10px;
}

.scroll-container::-webkit-scrollbar,
.scroll-container::-webkit-scrollbar-track {
  border-radius: 5px;
  background: 1px solid #707070;
  background-color: #ebedf5;
}

.scroll-container::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: var(--primary-color);
}

.ticket-header-info {
  margin-bottom: 15px;
}

.ticket-message {
  border-top: 1px solid var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
  padding: 15px 0px;
}

.ticket-reply-logs {
  padding: 15px 15px 0px 5px;
  list-style-type: none;
}

.reply {
  width: 260px;
  margin-bottom: 15px;
}

.reply p {
  margin: 0px;
  font-family: 'Mollen-LightNarrowItalic', sans-serif;
  font-size: 14px;
  color: var(--primary-color);
  text-transform: capitalize;
}

.reply-info {
  display: flex;
  justify-content: space-between;
}

.reply-admin {
  margin-left: auto;
  margin-right: 0;
}

.reply-merchant .date {
  order: 1;
}

.message-bubble {
  padding: 10px;
  border-radius: 5px;
  font-size: 17px;
}

.reply-admin .message-bubble {
  background: var(--secondary-color);
  color: #fff;
}

.reply-merchant .message-bubble {
  background: #ebedf5;
  color: var(--primary-color);
}

.message-box {
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
}

.message-box button {
  float: right;
}

.message-box button img {
  vertical-align: middle;
  height: 20px;
}

.message-box button:active {
  padding-top: 3px;
}

.message-box textarea {
  border: 1px solid var(--primary-color) !important;
  border-radius: 5px;
  font-family: 'Mollen-Regular', sans-serif;
  color: var(--primary-color);
  font-size: 17px;
  width: 490px;
  margin-right: 20px;
  padding: 3px;
}

.message-box textarea:focus {
  outline: none;
}

.message-box .textarea-force-height {
  height: 30px !important;
}

.action .v-btn {
  margin: 15px 0px !important;
}
</style>

export default {
  data: () => ({
    isEmptyOpts: {
      ignore_whitespace: true
    },
    isAlphaNumOpts: {
      ignore: '.'
    },
    isLocale: {
      alphaNum: 'en-US'
    },
    strongPwOpts: {
      minLength: 8,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 0
    },
    strongPwMsg: 'Password must contain at least one uppercase, one lowercase, one number. Password min. length is 8 characters.'

  })
}

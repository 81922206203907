import lsTokenMixin from '@/mixins/lsToken.js'

export default {
  mixins: [lsTokenMixin],
  methods: {
    logoutUser () {
      localStorage.removeItem(this.lsTokenKey)
      this.$store.dispatch('user', null)
      this.$router.push('/login')
    }
  }
}

<template>
  <h1 class="page-title">
    {{ title }}
  </h1>
</template>
<script>
export default {
  name: 'CommonPageTitle',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>
<style scoped>
.page-title {
  margin-top: 5px;
  margin-bottom: 25px;
  font-family: 'Mollen-SemiBold', sans-serif;
  font-size: 28px;
  color: var(--primary-color);
  text-transform: capitalize;
}
</style>

<template>
  <div>
    <v-form class="auth-custom-form">
      <h1 class="title-text">
        Success!
      </h1>
      <p class="sub-context">
        Your password has been reset successfully.
      </p>
      <div class="auth-action-footer p-bottom">
        <v-btn class="custom-btn-secondary" :ripple="false" block
               @click="$emit('request','authLogin')"
        >
          Sign In
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
export default {
  name: 'AuthSuccess'
}
</script>

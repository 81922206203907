<template>
  <div>
    <h1 class="auth-title">
      Welcome Back!
    </h1>

    <v-form ref="form" class="auth-custom-form">
      <v-text-field v-model="email" :rules="emailRules" class="custom-text-field" label="E-mail"
                    required input single-line @keyup.enter="validate"
      />

      <v-text-field v-model="password" :rules="passwordRules" label="Password" :type="show ? 'text' : 'password'"
                    class="custom-text-field" single-line @keyup.enter="validate"
      />

      <SvgEyeOff v-if="show" class="svg-icon-password" @click.native="show = !show" />
      <SvgEyeOn v-else class="svg-icon-password" @click.native="show = !show" />

      <div v-if="errorMessage" class="custom-error-container">
        {{ errorMessage }}
      </div>

      <div class="auth-action-footer">
        <v-btn class="custom-btn-secondary" :loading="loading" :disabled="loading" :ripple="false"
               block @click="validate"
        >
          Sign In
        </v-btn>
      </div>
    </v-form>
    <div class="panel-action" @click="$emit('request', 'authForgotPassword')">
      I forgot password
    </div>
  </div>
</template>
<script>
import SvgEyeOff from '@/components/svgs/SvgEyeOff.vue'
import SvgEyeOn from '@/components/svgs/SvgEyeOn.vue'
import getErrorMessageMixin from '@/mixins/getErrorMessage.js'
import currentAdminMixin from '@/mixins/currentAdmin.js'
import lsTokenMixin from '@/mixins/lsToken.js'
import validator from 'validator'

export default {
  name: 'AuthLogin',
  components: {
    SvgEyeOff,
    SvgEyeOn
  },
  mixins: [getErrorMessageMixin, currentAdminMixin, lsTokenMixin],
  data: () => ({
    token: '',
    show: false,
    email: '',
    emailRules: [
      v => !validator.isEmpty(v) || 'E-mail is required',
      v => validator.isEmail(v) || 'E-mail must be valid'
    ],
    password: '',
    passwordRules: [
      v => !validator.isEmpty(v) || 'Password is required'
    ],
    errorMessage: '',
    loading: false
  }),
  methods: {
    validate () {
      if (this.$refs.form.validate()) {
        this.handleSubmit()
      }
    },
    async handleSubmit () {
      this.loading = true

      try {
        const response = await this.$axios.post('/admin/auth/signin/', {
          email: this.email,
          password: this.password
        })

        // Set authorization token
        localStorage.setItem(this.lsTokenKey, `Bearer ${response.data.data.user.token}`)
        this.$axios.defaults.headers.common.Authorization = localStorage.getItem(this.lsTokenKey)

        // Store user data
        const user = await this.$axios.get('/admin/auth/user')
        this.$store.dispatch('user', user.data.data)

        if (this.currentAdmin.pwForceChange) return this.$router.push('/account-settings') // Redirect to account settings for password change
        this.$router.push('/') // Redirect to dashboard
      } catch (error) {
        this.errorMessage = this.getErrorMessage(error.response)
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.svg-icon-password {
  float: right;
  position: relative;
  bottom: 110px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {

  .svg-icon-password {
    bottom: 50px;
    height: 20px;
    width: 20px;
  }
}
</style>

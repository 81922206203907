<template>
  <div>
    <CommonPageTitle title="Logs > Admin Log" />
    <AdminLogDataTable />
  </div>
</template>
<script>

import CommonPageTitle from '@/components/common/CommonPageTitle.vue'
import AdminLogDataTable from '@/components/partials/logs/AdminLogDataTable.vue'
export default {
  name: 'AdminLog',
  components: {
    CommonPageTitle,
    AdminLogDataTable
  }
}
</script>

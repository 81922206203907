<template>
  <ul>
    <li class="shown">
      <button :disabled="value === 1" @click="prev()">
        Previous
      </button>
    </li>
    <li v-for="pageNumber in pageCount" :key="pageNumber" :class="{shown: Math.abs(pageNumber - value) < 3 || pageNumber === pageCount || pageNumber === 1 }">
      <button :class="{active: value === pageNumber,
                       last: (pageNumber === pageCount && Math.abs(pageNumber - value) > 3),
                       first:(pageNumber === 1 && Math.abs(pageNumber - value) > 3) }"
              @click="setPage(pageNumber)"
      >
        {{ pageNumber }}
      </button>
    </li>
    <li class="shown">
      <button :disabled="value === pageCount" @click="next()">
        Next
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'CommonPaginationLinks',
  props: {
    value: {
      // Current Page
      type: Number,
      default: 0
    },
    pageCount: {
      type: Number,
      default: 0
    }
  },
  methods: {
    next () {
      const page = this.value + 1
      this.$emit('input', page)
    },
    prev () {
      const page = this.value - 1
      this.$emit('input', page)
    },
    setPage (page) {
      this.$emit('input', page)
    }
  }
}
</script>

<style scoped>
  ul {
    float: right;
  }

  ul li {
    float: left;
    display: none;
    margin-right: 3px;
  }

  ul li button {
    font-family: 'Mollen-Regular', sans-serif;
    font-size: 14px;
    color: var(--primary-color);
    text-align: center;
    min-width: 20px;
    height: 20px;
    padding: 2px;
    border-radius: 20px !important;
    cursor: pointer;
  }

  .active {
    color: #fff ;
    background-color: var(--secondary-color);
    cursor: auto;
  }

  button:disabled {
    color: var(--disabled-color);
    cursor: auto;
  }

  button.first::after {
    content:'...'
  }

  button.last::before {
    content:'...'
  }

  .shown {
    display: block;
  }
</style>

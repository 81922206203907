<template>
  <v-dialog v-model="show" max-width="716px" persistent @keydown.esc="cancelAction('outside')"
            @click:outside="cancelAction('outside')"
  >
    <v-card class="main-card">
      <CommonPageTitle :title="selectedAction + ' Merchants'" />
      <v-form ref="form" v-model="valid">
        <CommonDetailPipe :items="pipeItems" size="small" :with-switch-view="false" />
        <div v-show="selectedFormCarouselIndex === 1" class="form-carousel-content">
          <v-row class="custom-row">
            <v-col cols="12" sm="4">
              <label class="custom-label">Store Name</label>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field v-model="storeName" :disabled="selectedAction === 'edit'" :rules="storeNameRules" required
                            outlined dense @keyup.enter="action"
              />
            </v-col>
          </v-row>

          <v-row class="custom-row">
            <v-col cols="12" sm="4">
              <label class="custom-label">Email</label>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field v-model="email" :rules="emailRules" required
                            outlined dense @keyup.enter="action"
              />
            </v-col>
          </v-row>

          <v-row v-if="selectedAction === 'add'" class="custom-row">
            <v-col cols="12" sm="4">
              <label class="custom-label">Password:</label>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field v-model="password" type="password" :rules="passwordRules" required
                            outlined dense @keyup.enter="action"
              />
            </v-col>
          </v-row>

          <v-row v-if="selectedAction === 'add'" class="custom-row">
            <v-col cols="12" sm="4">
              <label class="custom-label">Confirm Password:</label>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field v-model="passwordConfirm" type="password" :rules="passwordConfirmRules.concat(passwordConfirmationRule)"
                            required outlined dense @keyup.enter="action"
              />
            </v-col>
          </v-row>

          <v-row class="custom-row">
            <v-col cols="12" sm="4">
              <label class="custom-label">Merchant Type </label>
            </v-col>
            <v-col cols="12" sm="8">
              <label class="custom-radio-label">
                <input v-model="merchantType" type="radio" class="custom-radio" name="marchantType"
                       value="individual" checked :rules="merchantTypeRules"
                >Individual
              </label>
              <label class="custom-radio-label">
                <input v-model="merchantType" type="radio" class="custom-radio" name="marchantType"
                       value="business" :rules="merchantTypeRules"
                >Business
              </label>
            </v-col>
          </v-row>

          <v-row v-if="selectedAction === 'edit'" class="custom-row p-top">
            <v-col cols="12" sm="4">
              <label class="custom-label">Status</label>
            </v-col>
            <v-col cols="12" sm="8">
              <v-select v-model="newStatus" :items="status" :rules="[v => !!v || 'Item is required']" required
                        outlined dense class="dropdown"
              />
            </v-col>
          </v-row>
        </div>
        <div v-show="selectedFormCarouselIndex === 2" class="form-carousel-content">
          <v-row class="custom-row">
            <v-col cols="12" sm="4">
              <label class="custom-label">Address Line 1</label>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field v-model="addressLine1" :rules="addressLine1Rules" required
                            outlined dense @keyup.enter="action"
              />
            </v-col>
          </v-row>

          <v-row class="custom-row">
            <v-col cols="12" sm="4">
              <label class="custom-label">Address Line 2</label>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field v-model="addressLine2" outlined dense @keyup.enter="action" />
            </v-col>
          </v-row>

          <v-row class="custom-row">
            <v-col cols="12" sm="4">
              <label class="custom-label">City</label>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field v-model="city" :rules="cityRules" required outlined
                            dense @keyup.enter="action"
              />
            </v-col>
          </v-row>

          <v-row class="custom-row">
            <v-col cols="12" sm="4">
              <label class="custom-label">Postal Code</label>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field v-model="postalCode" :rules="postalCodeRules" required outlined
                            dense @keyup.enter="action"
              />
            </v-col>
          </v-row>
          <v-row class="custom-row">
            <v-col cols="12" sm="4">
              <label class="custom-label">Province</label>
            </v-col>
            <v-col cols="12" sm="8">
              <v-select v-model="province" :rules="provinceRules" :items="provinces" item-value="code"
                        item-text="name" required outlined dense
                        class="dropdown"
              />
            </v-col>
          </v-row>
        </div>
        <div v-show="selectedFormCarouselIndex === 3" class="form-carousel-content">
          <v-row class="custom-row">
            <v-col cols="12" sm="4">
              <label class="custom-label">Contact Person</label>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field v-model="contactPerson" :rules="contactPersonRules" required
                            outlined dense @keyup.enter="action"
              />
            </v-col>
          </v-row>

          <v-row class="custom-row">
            <v-col cols="12" sm="4">
              <label class="custom-label">Contact Email</label>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field v-model="contactEmail" :rules="contactEmailRules" required outlined
                            dense @keyup.enter="action"
              />
            </v-col>
          </v-row>

          <v-row class="custom-row">
            <v-col cols="12" sm="4">
              <label class="custom-label">Contact Number</label>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field v-model="contactNumber" :rules="contactNumberRules" required
                            outlined dense @keyup.enter="action"
              />
            </v-col>
          </v-row>
        </div>

        <div class="form-actions">
          <div v-if="errorMessage" class="custom-error-container">
            {{ errorMessage }}
          </div>
          <v-btn class="custom-btn-primary"
                 small
                 :disabled="loading"
                 :ripple="false"
                 @click="cancelAction('inside')"
          >
            {{ cancelButtonText }}
          </v-btn>
          <v-btn class="custom-btn-secondary"
                 :disabled="loading"
                 :loading="loading"
                 :ripple="false"
                 small
                 @click="action"
          >
            {{ saveButtonText }}
          </v-btn>
        </div>
      </v-form>
      <div class="form-carousel-nav-container">
        <ul v-if="!loading" class="form-carousel-nav">
          <li
            v-for="index in totalFormCarouselItems"
            :key="index"
            :class="{active:selectedFormCarouselIndex === index}"
            @click="switchFormCarouselContent(index)"
          />
        </ul>
      </div>
      <CommonProcessIndicator v-if="loading" />
    </v-card>
  </v-dialog>
</template>

<script>

import CommonPageTitle from '@/components/common/CommonPageTitle.vue'
import CommonDetailPipe from '@/components/common/CommonDetailPipe.vue'
import CommonProcessIndicator from '@/components/common/CommonProcessIndicator.vue'
import isNumberMixin from '@/mixins/isNumber.js'
import getErrorMessageMixin from '@/mixins/getErrorMessage.js'
import validator from 'validator'
import validateOptionsMixin from '@/mixins/validateOptions.js'
import isNameMixin from '@/mixins/isName.js'
import isContactNumberMixin from '@/mixins/isContactNumber.js'
import hasShowMixin from '@/mixins/hasShow.js'
export default {
  name: 'MerchantsForm',
  components: {
    CommonPageTitle,
    CommonDetailPipe,
    CommonProcessIndicator
  },
  mixins: [getErrorMessageMixin, isNumberMixin, hasShowMixin, validator, validateOptionsMixin, isNameMixin, isContactNumberMixin],
  props: {
    value: Boolean,
    merchantIds: {
      default: null,
      type: Array
    },
    selectedAction: {
      default: 'view',
      type: String
    },
    selectedStatus: {
      default: 'active',
      type: String
    }
  },
  data: () => ({
    selectedFormCarouselIndex: 1,
    totalFormCarouselItems: 3,
    cancelButtonText: 'Cancel',
    saveButtonText: 'Next',
    pipeItems: [{ label: 'General Information' }],
    queuedIndex: 0,
    valid: true,
    loading: false,
    errorMessage: '',
    storeName: '',
    storeNameRules: [
      v => !validator.isEmpty(v) || 'Store name is required',
      v => /^[a-zA-Z0-9 -+,@.'&]+$/.test(v.trim()) || 'Invalid store name'
    ],
    email: '',
    emailRules: [
      v => !validator.isEmpty(v) || 'Store email is required',
      v => validator.isEmail(v) || 'Store email must be valid'
    ],
    password: '',
    passwordRules: [
      v => !validator.isEmpty(v) || 'Password is required'
    ],
    passwordConfirm: '',
    passwordConfirmRules: [
      v => !validator.isEmpty(v) || 'Confirm password is required'
    ],
    merchantType: 'individual',
    merchantTypeRules: [v => !validator.isEmpty(v) || 'Merchant type is required'],
    newStatus: '',
    addressLine1: '',
    addressLine1Rules: [v => !validator.isEmpty(v) || 'Address line 1 is required'],
    addressLine2: '',
    city: '',
    cityRules: [v => !validator.isEmpty(v) || 'City is required'],
    provinceRules: [v => !validator.isEmpty(v) || 'Province is required'],
    postalCode: '',
    postalCodeRules: [
      v => !validator.isEmpty(v) || 'Postal code is required',
      v => validator.isNumeric(v) || 'Invalid postal code'
    ],
    province: '',
    contactPerson: '',
    contactPersonRules: [
      v => !validator.isEmpty(v) || 'Contact person is required'
    ],
    contactEmail: '',
    contactEmailRules: [
      v => !validator.isEmpty(v) || 'Contact email is required',
      v => validator.isEmail(v) || 'Invalid contact email'
    ],
    contactNumber: '',
    contactNumberRules: [
      v => !validator.isEmpty(v) || 'Contact number is required'
    ],
    provinces: [],
    status: ['active', 'pending', 'suspended', 'rejected']
  }),
  computed: {
    passwordConfirmationRule () {
      return () =>
        this.password === this.passwordConfirm || 'Confirm password is not equal to password'
    }
  },
  async created () {
    const provinces = await this.$axios.get('/public/provinces')
    this.provinces = provinces.data.data
    // Set default to first data
    this.province = provinces.data.data[0].code
    // Additional validation rules
    this.passwordRules.push(v => validator.isStrongPassword(v, this.strongPwOpts) || this.strongPwMsg)
    this.contactPersonRules.push(v => this.isName(v) || 'Invalid contact person name')
    this.contactNumberRules.push(v => this.isContactNumber(v) || 'Invalid contact number')
  },
  methods: {
    action () {
      const index = this.selectedFormCarouselIndex
      const totalItems = this.totalFormCarouselItems
      if (index === totalItems) {
        this.validate()
      } else {
        this.switchFormCarouselContent(index + 1)
      }
    },
    validate () {
      if (this.$refs.form.validate()) {
        this.handleSubmit()
      }
    },
    async handleSubmit () {
      this.loading = true

      if (this.selectedAction === 'add') {
        try {
          await this.$axios.post('/admin/merchants', {
            storeName: this.storeName,
            password: this.password,
            passwordConfirm: this.passwordConfirm,
            email: this.email,
            contact: {
              person: this.contactPerson,
              number: this.contactNumber,
              email: this.contactEmail
            },
            address: {
              addressLine1: this.addressLine1,
              addressLine2: this.addressLine2,
              city: this.city,
              province: this.province,
              postalCode: this.postalCode
            },
            merchantType: 'business'
          })
          this.switchFormCarouselContent(1)
          this.newStatus = 'pending'
          this.doneAction()
          this.loading = false
        } catch (error) {
          this.errorMessage = this.getErrorMessage(error.response)
          this.loading = false
        }
      } else {
        const id = this.merchantIds[this.queuedIndex]

        try {
          await this.$axios.put(`/admin/merchants/${id}`, {
            email: this.email,
            contact: {
              person: this.contactPerson,
              number: this.contactNumber,
              email: this.contactEmail
            },
            address: {
              addressLine1: this.addressLine1,
              addressLine2: this.addressLine2,
              city: this.city,
              province: this.province,
              postalCode: this.postalCode
            },
            merchantType: this.merchantType,
            status: this.newStatus
          })

          if (this.merchantIds.length - 1 > this.queuedIndex) {
            this.getNextData()
          } else {
            this.doneAction()
          }
          this.switchFormCarouselContent(1)
          this.loading = false
        } catch (error) {
          this.errorMessage = this.getErrorMessage(error.response)
          this.loading = false
        }
      }
    },
    retrieveData () {
      const id = this.merchantIds[this.queuedIndex]
      this.getMerchantData(id)
    },
    async getMerchantData (id) {
      this.loading = true
      this.errorMessage = ''
      try {
        const merchant = await this.$axios.get(`/admin/merchants/${id}`)

        // General Info
        this.storeName = merchant.data.data.storeName
        this.pipeItems.push({ label: this.storeName })

        this.email = merchant.data.data.email
        this.merchantType = merchant.data.data.merchantType
        this.newStatus = merchant.data.data.status

        // Address
        this.addressLine1 = merchant.data.data.address.addressLine1
        this.addressLine2 = merchant.data.data.address.addressLine2
        this.city = merchant.data.data.address.city
        this.postalCode = merchant.data.data.address.postalCode
        this.province = merchant.data.data.address.province

        // Contact Details
        this.contactPerson = merchant.data.data.contact.person
        this.contactEmail = merchant.data.data.contact.email
        this.contactNumber = merchant.data.data.contact.number

        this.selectedFormCarouselIndex = 1
        this.loading = false
      } catch (error) {
        this.errorMessage = this.getErrorMessage(error.response)
        this.loading = false
      }
    },
    resetForm () {
      this.storeName = ''
      this.email = ''
      this.merchantType = 'individual'
      this.addressLine1 = ''
      this.addressLine2 = ''
      this.city = ''
      this.province = this.provinces[0].code
      this.postalCode = ''
      this.contactPerson = ''
      this.contactEmail = ''
      this.contactNumber = ''
    },
    doneAction () {
      this.clearInputs()
      this.$emit('doneAction')
      this.queuedIndex = 0
      this.show = false
    },
    cancelAction (location) {
      if (!this.loading) {
        if (this.selectedAction === 'add' && this.cancelButtonText === 'Cancel') {
          this.doneAction()
        } else if (this.selectedAction === 'edit' && this.cancelButtonText === 'Cancel') {
        // Accomodate other checked items when editing
          if (this.merchantIds.length - 1 > this.queuedIndex) {
            this.getNextData()
          } else {
            this.doneAction()
          }
        } else {
          if (location === 'outside') {
            if (this.merchantIds.length - 1 > this.queuedIndex) {
              this.getNextData()
              this.switchFormCarouselContent(1)
            } else {
              this.doneAction()
            }
          } else {
            this.switchFormCarouselContent(this.selectedFormCarouselIndex - 1)
          }
        }
      }
    },
    getNextData () {
      const id = this.merchantIds[this.queuedIndex + 1]
      this.queuedIndex++
      this.getMerchantData(id)
      this.pipeItems.splice(1, 1)
    },
    clearInputs () {
      this.pipeItems.splice(1, 1)
      this.pipeItems[0].label = 'General Information'
      this.$refs.form.resetValidation()
      this.resetForm()
      this.errorMessage = ''
    },
    switchFormCarouselContent (index) {
      const totalItems = this.totalFormCarouselItems

      if (index === totalItems) {
        this.pipeItems[0].label = 'Contact Information'

        this.cancelButtonText = 'Back'
        this.saveButtonText = 'Save'
      } else if (index === 1) {
        this.pipeItems[0].label = 'General Information'
        this.cancelButtonText = 'Cancel'
        this.saveButtonText = 'Next'
      } else {
        this.pipeItems[0].label = 'Address'

        this.cancelButtonText = 'Back'
        this.saveButtonText = 'Next'
      }
      this.selectedFormCarouselIndex = index
    }
  }
}
</script>
<style scoped>
  .rates {
    width: 100px !important;
    text-align: center;
  }

  .p-top {
    padding-top: 28px;
  }
</style>

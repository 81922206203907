<template>
  <span>Showing {{ from }} to
    <span v-if="to < totalRecords">{{ to }}</span>
    <span v-else>{{ totalRecords }}</span>
    of {{ totalRecords }} entries
  </span>
</template>

<script>
export default {
  name: 'CommonPagination',
  props: {
    currentPage: {
      type: Number,
      default: 0
    },
    selectedEntry: {
      type: Number,
      default: 0
    },
    totalRecords: {
      type: Number,
      default: 0
    }
  },
  computed: {
    from () {
      return this.totalRecords ? this.to - this.selectedEntry + 1 : 0
    },
    to () {
      return this.currentPage * this.selectedEntry
    }
  }
}
</script>

<template>
  <v-dialog v-model="show" max-width="716px" persistent
            @keydown.esc="cancelAction()"
            @click:outside="cancelAction()"
  >
    <v-card class="main-card">
      <CommonPageTitle :title="selectedAction + ' User'" />
      <v-form ref="form" v-model="valid" @submit.prevent="validate">
        <div>
          <v-row class="custom-row">
            <v-col cols="12" sm="4">
              <label class="custom-label">Name</label>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field v-model="name" :rules="nameRules" required outlined
                            dense
              />
            </v-col>
          </v-row>
          <v-row class="custom-row">
            <v-col cols="12" sm="4">
              <label class="custom-label">Email</label>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field v-model="email" :rules="emailRules" required outlined
                            dense
              />
            </v-col>
          </v-row>
          <v-row v-if="selectedAction === 'add'" class="custom-row">
            <v-col cols="12" sm="4">
              <label class="custom-label">Password:</label>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field v-model="password" type="password" :rules="passwordRules" required
                            outlined dense
                            @input="applyPwdRelatedRules"
              />
            </v-col>
          </v-row>
          <v-row v-if="selectedAction === 'add'" class="custom-row">
            <v-col cols="12" sm="4">
              <label class="custom-label">Confirm Password:</label>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field ref="passwordConfirm" v-model="passwordConfirm" type="password"
                            :rules="passwordConfirmRules.concat(passwordConfirmationRule)" required outlined dense
              />
            </v-col>
          </v-row>
          <v-row class="custom-row">
            <v-col cols="12" sm="4">
              <label class="custom-label">IP Whitelist</label>
            </v-col>
            <v-col cols="12" sm="8">
              <v-textarea v-model="ipWhitelist" class="form-control" dense solo
                          :rules="ipWhitelistRules"
                          @input="applyIPRules"
              />
            </v-col>
          </v-row>
          <v-row class="custom-row">
            <v-col cols="12" sm="4">
              <label class="custom-label">Admin Level</label>
            </v-col>
            <v-col cols="12" sm="8">
              <v-select v-model="role" :items="roleLevels" item-value="code" item-text="name"
                        required outlined dense class="dropdown"
              />
            </v-col>
          </v-row>
          <v-row class="custom-row">
            <v-col cols="12" sm="4">
              <label>Force Change PW</label>
            </v-col>
            <v-col cols="12" sm="8">
              <label class="custom-radio-label">
                <input v-model="forceChangePW" type="radio" class="custom-radio" name="forceChange"
                       :value="true"
                       checked
                >True
              </label>
              <label class="custom-radio-label p-left">
                <input v-model="forceChangePW" type="radio" class="custom-radio" name="forceChange"
                       :value="false"
                >False
              </label>
            </v-col>
          </v-row>
          <v-row class="custom-row p-top">
            <v-col cols="12" sm="4">
              <label>Status</label>
            </v-col>
            <v-col cols="12" sm="8">
              <label class="custom-radio-label">
                <input v-model="active" type="radio" class="custom-radio" name="active"
                       :value="true"
                >Active
              </label>
              <label class="custom-radio-label">
                <input v-model="active" type="radio" class="custom-radio" name="active"
                       :value="false"
                >Inactive
              </label>
            </v-col>
          </v-row>
        </div>
        <div class="form-actions">
          <div v-if="errorMessage" class="custom-error-container">
            {{ errorMessage }}
          </div>
          <v-btn class="custom-btn-primary" small :disabled="loading" :ripple="false"
                 @click="cancelAction"
          >
            {{ cancelButtonText }}
          </v-btn>
          <v-btn class="custom-btn-secondary" :disabled="loading" :ripple="false" small
                 type="submit"
          >
            {{ saveButtonText }}
          </v-btn>
        </div>
      </v-form>
      <CommonProcessIndicator v-if="loading" />
    </v-card>
  </v-dialog>
</template>

<script>
import CommonPageTitle from '@/components/common/CommonPageTitle.vue'

import CommonProcessIndicator from '@/components/common/CommonProcessIndicator.vue'
import userRulesValidatorMixin from '@/mixins/userRulesValidator.js'
import swalAlertMixin from '@/mixins/swalAlert.js'
import hasShowMixin from '@/mixins/hasShow.js'

export default {
  name: 'UsersForm',
  components: {
    CommonPageTitle,
    CommonProcessIndicator
  },
  mixins: [userRulesValidatorMixin, swalAlertMixin, hasShowMixin],
  props: {
    value: Boolean,
    userIds: {
      default: null,
      type: Array
    },
    selectedAction: {
      default: 'view',
      type: String
    }
  },
  data: () => ({
    cancelButtonText: 'Cancel',
    saveButtonText: 'Save',
    valid: true,
    loading: false,
    errorMessage: '',
    name: '',
    email: '',
    password: '',
    passwordConfirm: '',
    forceChangePW: true,
    role: 'admin',
    ipWhitelist: [],
    active: true,
    roleLevels: ['accountant', 'admin', 'super'],
    queuedIndex: 0
  }),
  methods: {
    validate () {
      this.errorMessage = ''
      if (this.$refs.form.validate()) {
        this.handleSubmit()
      }
    },
    applyPwdRelatedRules () {
      if (this.password) {
        this.$refs.passwordConfirm.validate()
      } else {
        this.$refs.passwordConfirm.resetValidation()
      }
    },
    async handleSubmit () {
      this.loading = true
      if (this.selectedAction === 'add') {
        try {
          await this.$axios.post('/admin/admins', {
            name: this.name,
            email: this.email,
            password: this.password,
            passwordConfirm: this.passwordConfirm,
            pwForceChange: this.forceChangePW,
            role: this.role,
            ipWhitelist: this.ips.filter(val => val),
            active: this.active
          })
          this.doneAction()
          this.loading = false
        } catch (error) {
          this.errorMessage = this.getErrorMessage(error.response)
          this.loading = false
        }
      } else {
        let id = this.userIds[this.queuedIndex]

        try {
          await this.$axios.put(`/admin/admins/${id}`, {
            name: this.name,
            email: this.email,
            password: this.password,
            passwordConfirm: this.passwordConfirm,
            pwForceChange: this.forceChangePW,
            role: this.role,
            ipWhitelist: this.ips.filter(val => val),
            active: this.active

          })
          if (this.userIds.length - 1 > this.queuedIndex) {
            id = this.userIds[this.queuedIndex + 1]
            this.queuedIndex++
            this.getUsersData(id)
          } else {
            this.doneAction()

            // Reset
            this.queuedIndex = 0
          }
          this.loading = false
        } catch (error) {
          this.errorMessage = this.getErrorMessage(error.response)
          this.loading = false
        }
      }
    },
    retrieveData () {
      const id = this.userIds[this.queuedIndex]
      this.getUsersData(id)
    },
    async getUsersData (id) {
      this.loading = true
      this.errorMessage = ''
      try {
        const users = await this.$axios.get(`/admin/admins/${id}`)
        this.name = users.data.data.name
        this.email = users.data.data.email
        this.role = users.data.data.role
        this.forceChangePW = users.data.data.pwForceChange
        this.active = users.data.data.active
        this.ipWhitelist = users.data.data.ipWhitelist.join('\n')
        this.saveButtonText = 'Update'
        this.loading = false
      } catch (error) {
        this.errorMessage = this.getErrorMessage(error.response)
        this.loading = false
      }
    },
    resetForm () {
      this.name = ''
      this.email = ''
      this.role = 'admin'
      this.ipWhitelist = ''
      this.forceChangePW = true
      this.active = true
      this.password = ''
      this.passwordConfirm = ''
    },
    doneAction () {
      this.clearInputs()
      this.show = false
      this.$emit('doneAction')
    },
    cancelAction () {
      if (!this.loading) {
        if (this.selectedAction === 'add' && this.cancelButtonText === 'Cancel') {
          this.doneAction()
        } else if (this.selectedAction === 'edit' && this.cancelButtonText === 'Cancel') {
          // Accomodate other checked items when editing
          let id = this.userIds[this.queuedIndex]
          if (this.userIds.length - 1 > this.queuedIndex) {
            id = this.userIds[this.queuedIndex + 1]
            this.queuedIndex++
            this.getUsersData(id)
          } else {
            this.doneAction()
            this.queuedIndex = 0
          }
        }
      }
    },
    clearInputs () {
      this.$refs.form.resetValidation()
      this.resetForm()
      this.errorMessage = ''
      this.saveButtonText = 'Save'
    }
  }
}
</script>
<style scoped>
.p-left {
  padding-left: 13px;
}
</style>

export default {
  data: () => ({
    verbs: [
      'changed',
      'completed',
      'created',
      'deleted',
      'generated',
      'logged in',
      'registered',
      'resent',
      '\bset\b',
      'suspended',
      'unsuspended',
      'updated',
      'verified'
    ]
  }),
  methods: {
    highlightVerb (info) {
      const regex = new RegExp(this.verbs.join('|'), 'gi')
      return info.replace(regex, '<span class="info-highlight">$&</span>')
    }
  }
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import DashboardIndex from '@/pages/dashboard/DashboardIndex.vue'
import UsersIndex from '@/pages/users/UsersIndex.vue'
import MerchantsIndex from '@/pages/merchants/MerchantsIndex.vue'
import ShippingIndex from '@/pages/shipping/ShippingIndex.vue'
import OrdersIndex from '@/pages/orders/OrdersIndex.vue'
import PayablesIndex from '@/pages/payables/PayablesIndex.vue'
import MerchantFAQIndex from '@/pages/support/merchant/FAQIndex.vue'
import DepartmentsIndex from '@/pages/support/departments/DepartmentsIndex.vue'
import TicketsIndex from '@/pages/support/tickets/TicketsIndex.vue'
import AdminLogIndex from '@/pages/logs/AdminLogIndex.vue'
import MerchantLogIndex from '@/pages/logs/MerchantLogIndex.vue'
import PayableLogIndex from '@/pages/logs/PayableLogIndex.vue'
import AuthIndex from '@/pages/auth/AuthIndex.vue'
import UsersAccountSettingsIndex from '@/pages/accounts/UsersAccountSettingsIndex.vue'
import ErrorIndex from '@/pages/error/ErrorIndex.vue'
import PaymentOptionsIndex from '@/pages/paymentoptions/PaymentOptionsIndex.vue'

import store from '@/store'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  component: DashboardIndex,
  name: 'dashboard',
  meta: {
    title: 'Dashboard',
    layout: 'panel',
    requiresAuth: true
  }
},
{
  path: '/users',
  name: 'users',
  meta: {
    title: 'Users',
    layout: 'panel',
    requiresAuth: true
  },
  component: UsersIndex
},
{
  path: '/merchants',
  name: 'merchants',
  meta: {
    title: 'Merchants',
    layout: 'panel',
    requiresAuth: true
  },
  component: MerchantsIndex
},
{
  path: '/shipping',
  name: 'shipping',
  meta: {
    title: 'Shipping',
    layout: 'panel',
    requiresAuth: true
  },
  component: ShippingIndex
},
{
  path: '/orders',
  name: 'orders',
  meta: {
    title: 'Orders',
    layout: 'panel',
    requiresAuth: true
  },
  component: OrdersIndex
},
{
  path: '/payables',
  name: 'payables',
  meta: {
    title: 'Payables',
    layout: 'panel',
    requiresAuth: true
  },
  component: PayablesIndex
},
{
  path: '/payment-options',
  name: 'PaymentOptions',
  meta: {
    title: 'Payment Options',
    layout: 'panel',
    requiresAuth: true
  },
  component: PaymentOptionsIndex
},
{
  path: '/support/merchant-faq',
  name: 'merchantFAQs',
  meta: {
    title: 'Merchant FAQs',
    layout: 'panel',
    requiresAuth: true
  },
  component: MerchantFAQIndex
},
{
  path: '/support/departments',
  name: 'departments',
  meta: {
    title: 'Departments',
    layout: 'panel',
    requiresAuth: true
  },
  component: DepartmentsIndex
},
{
  path: '/support/tickets',
  name: 'tickets',
  meta: {
    title: 'Tickets',
    layout: 'panel',
    requiresAuth: true
  },
  component: TicketsIndex
},
{
  path: '/logs/admin',
  name: 'adminLog',
  meta: {
    title: 'Admin Log',
    layout: 'panel',
    requiresAuth: true
  },
  component: AdminLogIndex
},
{
  path: '/logs/merchant',
  name: 'merchantLog',
  meta: {
    title: 'Merchant Log',
    layout: 'panel',
    requiresAuth: true
  },
  component: MerchantLogIndex
},
{
  path: '/logs/payable',
  name: 'payableLog',
  meta: {
    title: 'Payable Log',
    layout: 'panel',
    requiresAuth: true
  },
  component: PayableLogIndex
},
{
  path: '/account-settings',
  component: UsersAccountSettingsIndex,
  name: 'accountSettings',
  meta: {
    title: 'User\'s Account Settings',
    layout: 'panel',
    requiresAuth: true
  }
},
{
  path: '/login',
  component: AuthIndex,
  name: 'login',
  meta: {
    title: 'Login',
    layout: 'general'
  }
},
{
  path: '/unauthorized',
  component: ErrorIndex,
  name: '401',
  meta: {
    title: 'Unauthorized',
    layout: 'general',
    requiresAuth: true
  }
},
{
  path: '/:notFound(.*)',
  component: ErrorIndex,
  name: '404',
  meta: {
    title: '404 Not Found',
    layout: 'general',
    requiresAuth: true
  }
}
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} - ANEC Admin Dashboard`

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.user) {
      next({
        path: '/login'
      })
    } else {
      if (to.name !== 'accountSettings' && store.state.user.currentAdmin.pwForceChange) {
        next({
          path: '/account-settings'
        })
      } else {
        next()
      }
    }
  } else {
    if (to.name === 'login' && store.state.user) {
      next({
        path: '/'
      })
    } else {
      next()
    }
  }
})

export default router

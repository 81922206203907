<template>
  <div>
    <CommonPageTitle title="Logs > Merchant log" />
    <MerchantLogDataTable />
  </div>
</template>
<script>

import CommonPageTitle from '@/components/common/CommonPageTitle.vue'
import MerchantLogDataTable from '@/components/partials/logs/MerchantLogDataTable.vue'
export default {
  name: 'MerchantLog',
  components: {
    CommonPageTitle,
    MerchantLogDataTable
  }
}
</script>


<template>
  <div>
    <v-card class="main-card">
      <CommonDataFilter @applyFilter="applyFilter" />
      <div class="custom-table">
        <table>
          <thead slot="head">
            <th class="a1-col">
              User <CommonSortIcons v-model="sort.key" sort-key="email" @applySort="sortBy" />
            </th>
            <th class="a2-col">
              Action <CommonSortIcons v-model="sort.key" sort-key="info" @applySort="sortBy" />
            </th>
            <th class="a3-col">
              IP Address <CommonSortIcons v-model="sort.key" sort-key="ip" @applySort="sortBy" />
            </th>
            <th class="a4-col">
              Date <CommonSortIcons v-model="sort.key" sort-key="updatedAt" default-sort="desc" @applySort="sortBy" />
            </th>
          </thead>
          <tbody>
            <tr v-for="row in sortedItems" :key="row.id">
              <td>{{ row.email }}</td>
              <td v-html="highlightVerb(row.info)" />
              <td>{{ row.ip }}</td>
              <td>{{ $dayjs(row.updatedAt).format('D MMMM YYYY, hh:mm A') }}</td>
            </tr>
            <tr v-if="!rowData.length">
              <td class="text-center" colspan="4">
                No data
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pagination-container">
        <CommonPaginationDetails :current-page="currentPage" :selected-entry="selectedEntry" :total-records="totalRecords" />
        <CommonPaginationLinks v-if="totalPages" v-model="currentPage" :page-count="totalPages" />
      </div>
    </v-card>
  </div>
</template>
<script>
import CommonDataFilter from '@/components/common/CommonDataFilter.vue'
import CommonPaginationDetails from '@/components/common/CommonPaginationDetails.vue'
import CommonPaginationLinks from '@/components/common/CommonPaginationLinks.vue'
import isPaginationMixin from '@/mixins/isPagination.js'
import isActionLogMixin from '@/mixins/isActionLog.js'
import CommonSortIcons from '@/components/common/CommonSortIcons.vue'
import tableSorterMixin from '@/mixins/tableSorter.js'

export default {
  name: 'AdminLogsDataTable',
  components: {
    CommonDataFilter,
    CommonPaginationDetails,
    CommonPaginationLinks,
    CommonSortIcons
  },
  mixins: [isPaginationMixin, isActionLogMixin, tableSorterMixin],
  data: () => ({
    search: '',
    sortUp: true,
    rowData: [],
    selectedAction: 'view',
    showDialog: false,
    id: ''
  }),
  created () {
    this.applyFilter(this.selectedStatus, this.selectedEntry, this.search)
  },
  methods: {
    applyFilter (selectedStatus, selectedEntry, search) {
      this.search = search
      this.selectedStatus = selectedStatus
      this.selectedEntry = selectedEntry
      this.getTotalData()
    },
    async getTotalData () {
      const rowCount = await this.$axios.get('/admin/logs/admins/count', {
        params: {
          search: this.search
        }
      })
      this.totalRecords = rowCount.data.data.count
      this.currentPaginationDisplay()
      this.getPageData()
    },
    async getPageData () {
      const response = await this.$axios.get('/admin/logs/admins', {
        params: {
          search: this.search,
          status: this.selectedStatus,
          perPage: this.selectedEntry,
          currentPage: this.currentPage
        }
      })
      const modifiedData = response.data.data.map(entry => {
        return {
          ...entry,
          email: entry.actionTaker.email
        }
      })
      this.rowData = modifiedData
    }
  }
}
</script>

<style scoped>
.a1-col {
  width: 140px ;
}

.a2-col {
  width: 380px ;
}

.a3-col {
  width: 150px ;
}

.a4-col {
  width: 150px ;
}

td >>> .info-highlight {
  color: var(--secondary-color);
}
</style>

<template>
  <div>
    <CommonPageTitle title="Merchants" />
    <MerchantsDataTable />
  </div>
</template>
<script>

import CommonPageTitle from '@/components/common/CommonPageTitle.vue'
import MerchantsDataTable from '@/components/partials/merchants/MerchantsDataTable.vue'

export default {
  name: 'Merchants',
  components: {
    CommonPageTitle,
    MerchantsDataTable
  }
}
</script>

<template>
  <v-dialog v-model="show" max-width="716px" persistent
            @keydown.esc="cancelAction()"
            @click:outside="cancelAction()"
  >
    <v-card class="main-card">
      <CommonPageTitle :title="selectedAction + ' Ticket'" />
      <v-form ref="form" v-model="valid" @submit.prevent="validate">
        <div>
          <v-row class="custom-row">
            <v-col cols="12" sm="4">
              <label class="custom-label">From</label>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field v-model="from" :disabled="true" required
                            outlined dense
              />
            </v-col>
          </v-row>
          <v-row class="custom-row">
            <v-col cols="12" sm="4">
              <label class="custom-label">Subject</label>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field v-model="subject" :disabled="true" required
                            outlined dense
              />
            </v-col>
          </v-row>
          <v-row class="custom-row">
            <v-col cols="12" sm="4">
              <label class="custom-label">Department</label>
            </v-col>
            <v-col cols="12" sm="8">
              <v-select v-model="department" :items="departmentItems" item-value="id"
                        item-text="name" required outlined dense
                        class="dropdown"
              />
            </v-col>
          </v-row>
          <v-row class="custom-row p-top">
            <v-col cols="12" sm="4">
              <label>Status</label>
            </v-col>
            <v-col cols="12" sm="8">
              <label class="custom-radio-label">
                <input v-model="status" type="radio" class="custom-radio" name="status"
                       value="open"
                >Open
              </label>
              <label class="custom-radio-label">
                <input v-model="status" type="radio" class="custom-radio" name="status"
                       value="resolved"
                >Resolved
              </label>
            </v-col>
          </v-row>
        </div>
        <div class="form-actions">
          <div v-if="errorMessage" class="custom-error-container">
            {{ errorMessage }}
          </div>
          <v-btn class="custom-btn-primary" small :disabled="loading" :ripple="false"
                 @click="cancelAction"
          >
            Cancel
          </v-btn>
          <v-btn class="custom-btn-secondary" small :disabled="loading" :ripple="false"
                 type="submit"
          >
            Save
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import CommonPageTitle from '@/components/common/CommonPageTitle.vue'
import hasShowMixin from '@/mixins/hasShow.js'
import getErrorMessageMixin from '@/mixins/swalAlert.js'
export default {
  name: 'TicketFormEdit',
  components: {
    CommonPageTitle
  },
  mixins: [hasShowMixin, getErrorMessageMixin],
  props: {
    value: Boolean,
    ticketIds: {
      default: null,
      type: Array
    },
    selectedAction: {
      default: 'view',
      type: String
    },
    selectedStatus: {
      default: 'active',
      type: String
    }
  },
  data: () => ({
    queuedIndex: 0,
    valid: true,
    loading: false,
    errorMessage: '',
    from: '',
    subject: '',
    department: '',
    status: 'open',
    departmentItems: []
  }),
  methods: {
    validate () {
      this.errorMessage = ''
      if (this.$refs.form.validate()) {
        this.handleSubmit()
      }
    },
    async handleSubmit () {
      this.loading = true
      let id = this.ticketIds[this.queuedIndex]
      try {
        await this.$axios.put(`/admin/support/tickets/${id}`, {
          status: this.status,
          department: this.department
        })
        if (this.ticketIds.length - 1 > this.queuedIndex) {
          id = this.ticketIds[this.queuedIndex + 1]
          this.queuedIndex++
          this.getTicketData(id)
        } else {
          this.doneAction()
          this.queuedIndex = 0
        }
        this.loading = false
      } catch (error) {
        this.errorMessage = this.getErrorMessage(error.response)
        this.loading = false
      }
    },
    retrieveData () {
      const id = this.ticketIds[this.queuedIndex]
      this.getTicketData(id)
    },
    async getTicketData (id) {
      this.loading = true
      this.errorMessage = ''
      try {
        const [ticket, departments] = await Promise.all([
          this.$axios.get(`/admin/support/tickets/${id}`),
          this.$axios.get('/admin/support/departments')
        ])
        const merchantEmail = await this.$axios.get(`/admin/merchants/${ticket.data.data.merchantId}`)
        this.departmentItems = departments.data.data
        this.from = merchantEmail.data.data.email
        this.subject = ticket.data.data.subject
        this.department = ticket.data.data.department
        this.status = ticket.data.data.status !== 'open' ? 'resolved' : 'open'
        this.loading = false
      } catch (error) {
        this.errorMessage = this.getErrorMessage(error.response)
        this.loading = false
      }
    },
    doneAction () {
      this.$emit('doneAction')
      this.queuedIndex = 0
      this.show = false
    },
    cancelAction () {
      if (!this.loading) {
        let id = this.ticketIds[this.queuedIndex]
        if (this.ticketIds.length - 1 > this.queuedIndex) {
          id = this.ticketIds[this.queuedIndex + 1]
          this.queuedIndex++
          this.getTicketData(id)
        } else {
          this.doneAction()
          this.queuedIndex = 0
        }
      }
    }
  }
}
</script>

<template>
  <v-app id="app" :style="{ backgroundImage: 'url('+require(`@/assets/images/loginbg.jpg`) }">
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: 'LayoutGeneral'
}
</script>
<style scoped>
#app {
  background: var(--main-bg-color);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
</style>

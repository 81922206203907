<template>
  <div>
    <v-card class="main-card">
      <CommonDataFilter class="custom-filter-alignment" :action="action" :action-hide="true"
                        @applyAction="handleAction"
                        @applyFilter="applyFilter"
      />

      <div class="custom-table">
        <table>
          <thead slot="head">
            <th class="checkbox-col">
              <!-- checkbox -->
            </th>
            <th class="a1-col">
              Date Added  <CommonSortIcons v-model="sort.key" sort-key="createdAt" default-sort="desc" @applySort="sortBy" />
            </th>
            <th class="a1-col">
              Date Completed <CommonSortIcons v-model="sort.key" sort-key="completedAt" @applySort="sortBy" />
            </th>
            <th class="a2-col">
              Store  <CommonSortIcons v-model="sort.key" sort-key="storeName" @applySort="sortBy" />
            </th>
            <th class="a3-col">
              Order #  <CommonSortIcons v-model="sort.key" sort-key="orderId" @applySort="sortBy" />
            </th>
            <th class="a4-col">
              Order Total  <CommonSortIcons v-model="sort.key" sort-key="subTotalOrders" @applySort="sortBy" />
            </th>
            <th class="a4-col">
              Deductions <CommonSortIcons v-model="sort.key" sort-key="subTotalDeductions" @applySort="sortBy" />
            </th>
            <th class="a4-col">
              Payables  <CommonSortIcons v-model="sort.key" sort-key="total" @applySort="sortBy" />
            </th>
            <th class="a5-col">
              Status  <CommonSortIcons v-model="sort.key" sort-key="status" @applySort="sortBy" />
            </th>
            <th class="a3-col no-border">
              <!-- Button Details -->
            </th>
          </thead>
          <tbody>
            <tr v-for="row in sortedItems" :key="row.id">
              <td class="text-center">
                <input :id="row.id" v-model="checkedItems" :value="row.id" name="payablesid"
                       type="checkbox"
                >
              </td>
              <td>{{ row.createdAt ? $dayjs(row.createdAt).format('MM/DD/YYYY') : 'N/A' }}</td>
              <td>{{ row.completedAt ? $dayjs(row.completedAt).format('MM/DD/YYYY') : 'N/A' }}</td>
              <td>{{ row.storeName }}</td>
              <td>#{{ row.orderId }}</td>
              <td>{{ row.subTotalOrders.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' }) }}</td>
              <td>{{ row.subTotalDeductions.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' }) }}</td>
              <td>{{ row.total.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' }) }} </td>
              <td class="format">
                {{ row.status }}
              </td>
              <td class="text-center no-border">
                <v-btn class="custom-btn-secondary" :ripple="false" small @click="actionDetails(row.id)">
                  View Details
                </v-btn>
              </td>
            </tr>
            <tr v-if="!rowData.length">
              <td class="text-center" colspan="9">
                No data
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pagination-container custom-pagination-alignment">
        <CommonPaginationDetails :current-page="currentPage" :selected-entry="selectedEntry"
                                 :total-records="totalRecords"
        />
        <CommonPaginationLinks v-if="totalPages" v-model="currentPage" :page-count="totalPages" />
      </div>
    </v-card>
    <PayablesForm ref="payablesFormRef" v-model="showDialog" :selected-action="selectedAction"
                  :payable-ids="checkedItems" @doneAction="getTotalData"
    />
    <PayablesDetailsForm ref="payablesDetailsFormRef" v-model="showDialogDetails" :payable-ids="id" />
  </div>
</template>
<script>
import CommonDataFilter from '@/components/common/CommonDataFilter.vue'
import CommonPaginationDetails from '@/components/common/CommonPaginationDetails.vue'
import CommonPaginationLinks from '@/components/common/CommonPaginationLinks.vue'
import isPaginationMixin from '@/mixins/isPagination.js'
import PayablesForm from '@/components/partials/payables/PayablesForm.vue'
import PayablesDetailsForm from '@/components/partials/payables/PayablesDetailsForm.vue'
import CommonSortIcons from '@/components/common/CommonSortIcons.vue'
import tableSorterMixin from '@/mixins/tableSorter.js'
export default {
  name: 'PayablesDataTable',
  components: {
    CommonDataFilter,
    CommonPaginationDetails,
    CommonPaginationLinks,
    PayablesForm,
    PayablesDetailsForm,
    CommonSortIcons
  },
  mixins: [isPaginationMixin, tableSorterMixin],
  data: () => ({
    search: '',
    rowData: [],
    checkedItems: [],
    selectedAction: 'view',
    showDialog: false,
    showDialogDetails: false,
    currentDate: '',
    id: '',
    intervalDate: '',
    action: [
      'view',
      'edit',
      'export'
    ],
    selectedTimeFrame: 'Today',
    selectedPaymentStatus: 'All'
  }),
  computed: {
    rangeFromDate () {
      return this.selectedTimeFrame === 'All time' ? null : this.intervalDate
    },
    rangeToDate () {
      return this.selectedTimeFrame === 'All time' ? null : this.currentDate
    },
    paymentStatus () {
      return this.selectedPaymentStatus === 'All' ? null : this.selectedPaymentStatus === 'Paid' ? 'paid' : 'unpaid'
    }
  },
  created () {
    this.applyFilter(this.selectedStatus, this.selectedEntry, this.search, this.selectedTimeFrame, this.selectedPaymentStatus)
  },
  methods: {
    applyFilter (selectedStatus, selectedEntry, search, selectedTimeFrame, selectedPaymentStatus) {
      const today = new Date()
      switch (selectedTimeFrame) {
        case 'Today': {
          today.setDate(today.getDate() - 0)
          break
        }
        case 'This past week': {
          today.setDate(today.getDate() - 7)
          break
        }
        case 'This past month': {
          today.setMonth(today.getMonth() - 1)
          break
        }
        case 'This past year': {
          today.setFullYear(today.getFullYear() - 1)
          break
        }
        default: {
          break
        }
      }
      this.currentDate = new Date()
      this.intervalDate = today
      this.intervalDate = this.intervalDate.toISOString().slice(0, 10)
      this.currentDate = this.currentDate.toISOString().slice(0, 10)
      this.search = search
      this.selectedEntry = selectedEntry
      this.selectedTimeFrame = selectedTimeFrame
      this.selectedPaymentStatus = selectedPaymentStatus
      this.selectedStatus = selectedStatus

      this.getTotalData()
    },
    async getTotalData () {
      const rowCount = await this.$axios.get('/admin/merchant-payables/count', {
        params: {
          search: this.search,
          dateFrom: this.selectedPaymentStatus === 'Paid' ? null : this.rangeFromDate,
          dateTo: this.selectedPaymentStatus === 'Paid' ? null : this.rangeToDate,
          completedFrom: this.selectedPaymentStatus === 'Paid' ? this.rangeFromDate : null,
          completedTo: this.selectedPaymentStatus === 'Paid' ? this.rangeToDate : null,
          status: this.paymentStatus
        }
      })

      this.totalRecords = rowCount.data.data.count
      this.currentPaginationDisplay()
      this.getPageData()
    },
    async getPageData () {
      const response = await this.$axios.get('/admin/merchant-payables', {
        params: {
          search: this.search,
          perPage: this.selectedEntry,
          currentPage: this.currentPage,
          dateFrom: this.selectedPaymentStatus === 'Paid' ? null : this.rangeFromDate,
          dateTo: this.selectedPaymentStatus === 'Paid' ? null : this.rangeToDate,
          completedFrom: this.selectedPaymentStatus === 'Paid' ? this.rangeFromDate : null,
          completedTo: this.selectedPaymentStatus === 'Paid' ? this.rangeToDate : null,
          status: this.paymentStatus
        }
      })

      const modifiedData = response.data.data.map(entry => {
        return {
          ...entry,
          storeName: `${entry.merchant.storeName} (#${entry.merchant.id})`,
          subTotalOrders: entry.items.reduce((a, b) => a + (b.unitPrice) * (b.quantity), 0),
          subTotalDeductions: entry.items.reduce((a, b) => a + (b.deductionSubtotal), 0)
        }
      })
      this.rowData = modifiedData
    },
    handleAction (action) {
      this.selectedAction = action
      if (action === 'edit') {
        if (this.checkedItems.length) {
          this.$refs.payablesFormRef.retrieveData()
          this.showDialog = true
        }
      }
    },
    actionDetails (payableID) {
      this.$refs.payablesDetailsFormRef.getPayablesData(payableID)
      this.showDialogDetails = true
    }
  }
}
</script>
<style scoped>
.a1-col {
  width: 180px;
}

.a2-col {
  width: 180px;
}

.a3-col {
  width: 140px;
}

.a4-col {
  width: 150px;
}

.a5-col {
  width: 100px;
}

.format {
  text-transform: capitalize;
}

.custom-btn-secondary {
  width: 110px;
}

.no-border {
  padding-left: 25px;
}

.custom-filter-alignment {
  margin-right: 140px;
}

.custom-pagination-alignment {
  margin-right: 133px;
}

@media only screen and (max-width: 600px) {

  .custom-filter-alignment, .custom-pagination-alignment {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 600px) {

  .custom-filter-alignment, .custom-pagination-alignment {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 768px) {

  .custom-filter-alignment, .custom-pagination-alignment {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 992px) {

  .custom-filter-alignment, .custom-pagination-alignment {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 1200px) {

  .custom-filter-alignment {
    margin-right: 140px;
  }

  .custom-pagination-alignment {
    margin-right: 133px
  }
}
</style>

<template>
  <div>
    <v-card class="main-card">
      <CommonDataFilter class="custom-alignment" @applyFilter="applyFilter" />
      <div class="custom-table">
        <table>
          <thead slot="head">
            <th>
              ANEC ID <CommonSortIcons v-model="sort.key" sort-key="id" @applySort="sortBy" />
            </th>
            <th>
              E-Commerce ID <CommonSortIcons v-model="sort.key" sort-key="ecommerceId" @applySort="sortBy" />
            </th>
            <th>
              Merchant ID <CommonSortIcons v-model="sort.key" sort-key="merchantId" @applySort="sortBy" />
            </th>
            <th>
              Date Added <CommonSortIcons v-model="sort.key" sort-key="createdAt" default-sort="desc" @applySort="sortBy" />
            </th>
            <th>
              Payment <CommonSortIcons v-model="sort.key" sort-key="payment" @applySort="sortBy" />
            </th>
            <th>
              Status <CommonSortIcons v-model="sort.key" sort-key="status" @applySort="sortBy" />
            </th>
            <th class="no-border">
              <!-- Button Details -->
            </th>
          </thead>
          <tbody>
            <tr v-for="row in sortedItems" :key="row.id">
              <td>#{{ row.id }}</td>
              <td>#{{ row.ecommerceId }}</td>
              <td>#{{ row.merchantId }}</td>
              <td>{{ $dayjs(row.createdAt).format('D MMMM YYYY') }}</td>
              <td class="custom-column-format">
                {{ row.payment }}
              </td>
              <td class="custom-column-format">
                {{ row.status }}
              </td>
              <td class="text-center no-border">
                <v-btn class="custom-btn-secondary" :ripple="false" small @click="viewDetails(row.ecommerceId)">
                  View Details
                </v-btn>
              </td>
            </tr>
            <tr v-if="rowData.length === 0">
              <td class="text-center" colspan="7">
                No data
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pagination-container custom-alignment">
        <CommonPaginationDetails :current-page="currentPage" :selected-entry="selectedEntry" :total-records="totalRecords" />
        <CommonPaginationLinks v-if="totalPages" v-model="currentPage" :page-count="totalPages" />
      </div>
    </v-card>
  </div>
</template>
<script>
import CommonDataFilter from '@/components/common/CommonDataFilter.vue'
import CommonPaginationDetails from '@/components/common/CommonPaginationDetails.vue'
import CommonPaginationLinks from '@/components/common/CommonPaginationLinks.vue'
import isPaginationMixin from '@/mixins/isPagination.js'
import CommonSortIcons from '@/components/common/CommonSortIcons.vue'
import tableSorterMixin from '@/mixins/tableSorter.js'
export default {
  name: 'OrdersDataTable',
  components: {
    CommonDataFilter,
    CommonPaginationDetails,
    CommonPaginationLinks,
    CommonSortIcons
  },
  mixins: [isPaginationMixin, tableSorterMixin],
  data: () => ({
    search: '',
    rowData: [],
    checkedProducts: [],
    selectedAction: 'view',
    selectedStatus: '',
    showDialog: false,
    id: ''
  }),
  created () {
    this.applyFilter(this.selectedStatus, this.selectedEntry, this.search)
  },
  methods: {
    applyFilter (selectedStatus, selectedEntry, search) {
      this.search = search
      this.selectedStatus = selectedStatus
      this.selectedEntry = selectedEntry
      this.getTotalData()
    },
    async getTotalData () {
      const rowCount = await this.$axios.get('/admin/orders/count', {
        params: {
          search: this.search
        }
      })
      this.totalRecords = rowCount.data.data.count
      this.currentPaginationDisplay()
      this.getPageData()
    },
    async getPageData () {
      const response = await this.$axios.get('/admin/orders', {
        params: {
          search: this.search,
          status: this.selectedStatus,
          perPage: this.selectedEntry,
          currentPage: this.currentPage
        }
      })
      const modifiedData = response.data.data.map(entry => {
        return {
          ...entry,
          merchantId: entry.merchant.id,
          payment: entry.payment.status
        }
      })
      this.rowData = modifiedData
    },
    viewDetails (ecommerceId) {
      const ecommerceUrl = process.env.VUE_APP_ECOMMERCE_URL
      window.open(ecommerceUrl + '/' + ecommerceId, '_blank')
    }
  }
}
</script>
<style scoped>

.custom-btn-secondary {
  width: 150px;
  margin-right: 50px;
}

.custom-alignment {
  margin-right: 198px
}

@media only screen and (max-width: 600px) {

  .custom-btn-secondary {
    width: 120px;
  }

  .no-border {
    padding-left: 15px;
  }

  .custom-alignment {
    margin-right: 10px
  }
}
</style>

<template>
  <div class="container">
    <CommonCard :title-description="titleDescription" :main-route="mainRoute" :sub-route="subRoute"
                :custom-element="true"
    />
  </div>
</template>
<script>
import CommonCard from '@/components/common/CommonCard.vue'
export default {
  name: 'ShippingDataColumns',
  components: {
    CommonCard
  },
  data: () => ({
    titleDescription: 'Courier',
    mainRoute: '/admin/couriers',
    subRoute: '/admin/couriers'
  })
}
</script>
<style scoped>
.container {
  margin-top:40px;
}
</style>

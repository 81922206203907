<template>
  <div>
    <CommonPageTitle title="Support > Departments" />
    <DepartmentDataColumns />
  </div>
</template>
<script>

import CommonPageTitle from '@/components/common/CommonPageTitle.vue'
import DepartmentDataColumns from '@/components/partials/support/departments/DepartmentDataColumns.vue'

export default {
  name: 'Merchants',
  components: {
    CommonPageTitle,
    DepartmentDataColumns
  }
}
</script>

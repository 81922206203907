<template>
  <div>
    <CommonPageTitle title="Account Settings" />
    <UsersAccountSettingsForm />
  </div>
</template>
<script>

import CommonPageTitle from '@/components/common/CommonPageTitle.vue'
import UsersAccountSettingsForm from '@/components/partials/accounts/UsersAccountSettingsForm.vue'
import updateUserDataMixin from '@/mixins/updateUserData.js'
export default {
  name: 'UsersAccountSettings',
  components: {
    CommonPageTitle,
    UsersAccountSettingsForm
  },
  mixins: [updateUserDataMixin],
  async beforeRouteLeave (to, from, next) {
    await this.updateUserData()
    next()
  }
}
</script>

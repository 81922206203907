<template>
  <div>
    <h1 class="auth-title p-top">
      {{ titleHeader }}
    </h1>
    <v-form ref="form" class="auth-custom-form" @submit.prevent="validate">
      <v-text-field v-model="email" :rules="emailRules" class="custom-text-field" label="Email"
                    single-line type="email" required
      />

      <div v-if="errorMessage" v-show="isVisible" class="custom-error-container">
        * {{ errorMessage }}
      </div>
      <div class="auth-action-footer">
        <v-btn type="submit" class="custom-btn-secondary" block :ripple="false"
               :loading="loading" :disabled="loading"
        >
          Request a request token
        </v-btn>
      </div>
    </v-form>
    <div class="auth-action-footer">
      <v-btn type="submit" class="custom-btn-primary" block :ripple="false"
             @click="resetField(), $emit('request','authPasswordResetOne')"
      >
        I have a request token
      </v-btn>
    </div>
    <div class="panel-action" @click="$emit('request','authLogin')">
      Back to login
    </div>
  </div>
</template>
<script>
import getErrorMessageMixin from '@/mixins/getErrorMessage.js'
import validator from 'validator'
export default {
  name: 'AuthForgotPassword',
  mixins: [getErrorMessageMixin],
  data () {
    return {
      show1: false,
      email: '',
      emailRules: [
        v => !validator.isEmpty(v) || 'E-mail is required',
        v => validator.isEmail(v) || 'E-mail must be valid'
      ],
      accountType: 'admin',
      errorMessage: '',
      isVisible: false,
      loading: false,
      titleHeader: 'Password Reset'

    }
  },
  methods: {
    validate () {
      if (this.$refs.form.validate()) {
        this.handleSubmit()
      }
    },
    async handleSubmit () {
      this.loading = true
      try {
        await this.$axios.put('/admin/account/pwreset', {
          email: this.email,
          accountType: this.accountType
        })
        this.isVisible = false
        this.loading = false
        this.titleHeader = 'Your request token has been sent to your email.'
      } catch (error) {
        this.errorMessage = this.getErrorMessage(error.response)
        this.isVisible = true
        this.loading = false
      }
    },
    resetField () {
      this.titleHeader = 'Password Reset'
    }
  }
}
</script>

<template>
  <v-dialog v-model="show" max-width="716px" persistent
            @keydown.esc="cancelAction()"
            @click:outside="cancelAction()"
  >
    <v-card class="main-card">
      <CommonPageTitle :title="selectedAction + ' Ticket'" />
      <v-form ref="form" v-model="valid" @submit.prevent="validate">
        <div>
          <v-row class="custom-row">
            <v-col cols="12" sm="4">
              <label class="custom-label">Merchant</label>
            </v-col>
            <v-col cols="12" sm="8">
              <v-autocomplete
                ref="merchant"
                v-model="merchant"
                :items="merchantList"
                :loading="loading"
                :search-input.sync="search"
                :rules="merchantRules"
                placeholder="Seach..."
                hide-no-data
                hide-selected
                item-text="storeName"
                item-value="id"
                return-object
                outlined
                dense
              />
            </v-col>
          </v-row>
          <v-row class="custom-row">
            <v-col cols="12" sm="4">
              <label class="custom-label">Subject</label>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field v-model="subject" :rules="subjectRules" outlined dense />
            </v-col>
          </v-row>
          <v-row class="custom-row">
            <v-col cols="12" sm="4">
              <label class="custom-label">Department</label>
            </v-col>
            <v-col cols="12" sm="8">
              <v-select v-model="department" :items="departmentItems" item-value="id"
                        item-text="name" required outlined dense
                        class="dropdown"
              />
            </v-col>
          </v-row>
          <v-row class="custom-row">
            <v-col cols="12" sm="4">
              <label class="custom-label">Message</label>
            </v-col>
            <v-col cols="12" sm="8">
              <v-textarea v-model="message" class="form-control" dense solo
                          :rules="messageRules"
              />
            </v-col>
          </v-row>
        </div>
        <div class="form-actions">
          <div v-if="errorMessage" class="custom-error-container">
            {{ errorMessage }}
          </div>
          <v-btn class="custom-btn-primary" small :disabled="loading" :ripple="false"
                 @click="cancelAction"
          >
            Cancel
          </v-btn>
          <v-btn class="custom-btn-secondary" small :disabled="loading" :ripple="false"
                 type="submit"
          >
            Save
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import CommonPageTitle from '@/components/common/CommonPageTitle.vue'
import hasShowMixin from '@/mixins/hasShow.js'
import getErrorMessageMixin from '@/mixins/swalAlert.js'
import validator from 'validator'
export default {
  name: 'TicketFormAdd',
  components: {
    CommonPageTitle
  },
  mixins: [hasShowMixin, getErrorMessageMixin],
  props: {
    value: Boolean,
    selectedAction: {
      default: 'view',
      type: String
    },
    selectedStatus: {
      default: 'active',
      type: String
    }
  },
  data: () => ({
    queuedIndex: 0,
    valid: true,
    loading: false,
    errorMessage: '',
    merchant: '',
    merchantList: [],
    merchantRules: [],
    merchantErrorMessage: 'No merchant selected',
    search: '',
    merchantIdRules: [
      v => !validator.isEmpty(v) || 'Merchant id is required',
      v => validator.isNumeric(v) || 'Merchant id should be numeric'
    ],
    subject: '',
    subjectRules: [
      v => !validator.isEmpty(v) || 'Subject is required'
    ],
    message: '',
    messageRules: [
      v => !validator.isEmpty(v) || 'Message is required'
    ],
    department: '',
    departmentItems: []
  }),
  watch: {
    async search (val) {
      if (val) {
        this.loading = true
        try {
          const response = await this.$axios.get('/admin/merchants', {
            params: {
              search: val,
              perPage: 30,
              currentPage: 1,
              sort: 'name'
            }
          })
          this.merchantList = response.data.data
          this.merchantRules = []
          this.loading = false
        } catch (error) {
          this.errorMessage = this.getErrorMessage(error.response)
          this.loading = false
        }
      } else {
        this.merchantRules.push(false || this.merchantErrorMessage)
        this.$refs.merchant.validate()
        this.merchant = ''
      }
    }
  },
  methods: {
    validate () {
      this.errorMessage = ''
      if (!this.merchant) {
        this.merchantRules.push(false || this.merchantErrorMessage)
      }
      if (this.$refs.form.validate()) {
        this.handleSubmit()
      }
    },
    async handleSubmit () {
      this.loading = true
      try {
        await this.$axios.post('/admin/support/tickets', {
          merchantId: this.merchant.id,
          department: this.department,
          subject: this.subject,
          message: this.message
        })
        this.doneAction()
        this.loading = false
      } catch (error) {
        this.errorMessage = this.getErrorMessage(error.response)
        this.loading = false
      }
    },
    async retrieveData () {
      const departments = await this.$axios.get('/admin/support/departments')
      this.departmentItems = departments.data.data
      this.department = this.departmentItems[0].id
    },
    clearInputs () {
      this.errorMessage = ''
      this.merchant = ''
      this.search = ''
      this.subject = ''
      this.message = ''
      this.department = this.departmentItems[0].id
      this.$refs.form.resetValidation()
    },
    doneAction () {
      this.clearInputs()
      this.$emit('doneAction')
      this.show = false
    },
    cancelAction () {
      if (!this.loading) {
        this.doneAction()
      }
    }
  }
}
</script>

<template>
  <div>
    <CommonPageTitle title="Dashboard" />
    <DashboardDataSummary />
    <DashboardActivities />
  </div>
</template>
<script>

import CommonPageTitle from '@/components/common/CommonPageTitle.vue'
import DashboardDataSummary from '@/components/partials/dashboard/DashboardDataSummary.vue'
import DashboardActivities from '@/components/partials/dashboard/DashboardActivities.vue'

export default {
  name: 'Merchants',
  components: {
    CommonPageTitle,
    DashboardDataSummary,
    DashboardActivities
  }
}
</script>

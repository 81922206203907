<template>
  <div>
    <CommonPageTitle title="Users" />
    <UsersDataTable />
  </div>
</template>
<script>

import CommonPageTitle from '@/components/common/CommonPageTitle.vue'
import UsersDataTable from '@/components/partials/users/UsersDataTable.vue'

export default {
  name: 'Users',
  components: {
    CommonPageTitle,
    UsersDataTable
  }
}
</script>

<template>
  <v-container class="error-page-container">
    <h1 class="header-text">
      {{ $route.name }}
    </h1>
    <p :class="['sub-context', { 'sub-info' : $route.name === '404' }]">
      {{ message }}
    </p>
    <v-btn type="button" class="custom-btn-secondary" block :ripple="false"
           @click="$router.push('/')"
    >
      Go Back
    </v-btn>
  </v-container>
</template>

<script>
export default {
  name: 'ErrorIndex',
  computed: {
    message () {
      return this.$route.name === '404' ? 'An error has occured. Please try \n going back to the home page.' : 'Unauthorized'
    }
  }
}
</script>

<style scoped>
.error-page-container {
  margin: auto;
  text-align: center;
  max-width: 415px;
  color: #fff;
}

.header-text {
  font-family: 'Bolt-Regular', sans-serif;
  font-size: 200px;
}

.sub-context {
  font-family: 'Mollen-Regular', sans-serif;
  font-size: 32px;
  margin-bottom: 50px;
}

.sub-info {
  margin: 0;
  padding: 15px 0px;
  font-size: 18px;
  white-space: pre;
}
</style>
